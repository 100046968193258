import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { BrokerProperties } from "../BrokerProperties";

const RealEstateAgent = ({ base_route = "broker", isREAgents = false }) => {
  const dispatch = useDispatch();
  const { broker } = useParams();
  const navigate = useNavigate();

  const { agentProfileData } = useSelector((state) => state?.brokerAgents);
  const { agentAdminLoader } = useSelector((state) => state?.agentsAdmin);

  return (
    <div className="broker-create-account admin_wrapper">
      <BrokerProperties base_route={base_route} isREAgents={isREAgents} />
    </div>
  );
};

export default RealEstateAgent;
