import { createSlice } from "@reduxjs/toolkit";

const intialAgentAdminState = {
  agentAdminDetails: {},
  agentOfficeDetails: {},
  agentsDetails: {},
  createAgentAdminDetails: {},
  createAgentOfficeDetails: {},
  agentAdminLoader: false,
  createAdminLoader: false,
};

const agentsAdmin = createSlice({
  name: "agentAdminDetails",
  initialState: intialAgentAdminState,
  reducers: {
    resetAgentsAdminState: () => intialAgentAdminState,
    getAdminProfileData(state, action) {
      state.agentAdminLoader = true;
    },
    fetchAdminProfileData(state, action) {
      state.agentProfileListData = action.payload;
      state.agentAdminLoader = false;
    },
    getAgentAdminDetails(state, action) {
      state.agentAdminLoader = true;
    },
    fetchAgentAdminData(state, action) {
      state.agentAdminDetails = action.payload;
      state.agentAdminLoader = false;
    },
    createAgentAdminDetails(state, action) {
      state.createAdminLoader = true;
    },
    fetchCreateAgentAdminData(state, action) {
      state.createAgentAdminDetails = action.payload;
      state.createAdminLoader = false;
    },
    getAgentsDetails(state, action) {
      state.agentAdminLoader = true;
    },
    fetchAgentsData(state, action) {
      state.agentsDetails = action.payload;
      state.agentAdminLoader = false;
    },
    getAgentOfficeDetails(state, action) {
      state.agentAdminLoader = true;
    },
    fetchAgentOfficeData(state, action) {
      state.agentOfficeDetails = action.payload;
      state.agentAdminLoader = false;
    },
    createAgentOfficeDetails(state, action) {
      state.agentAdminLoader = true;
    },
    fetchCreateAgentOfficeData(state, action) {
      state.createAgentOfficeDetails = action.payload;
      state.agentAdminLoader = false;
    },
  },
});
export const agentsAdminAction = agentsAdmin.actions;
export default agentsAdmin.reducer;
