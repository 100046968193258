import React, { useEffect, useState } from "react";

import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Avatar, Button } from "@mui/material";

import AgentsList from "./AgentsList";
import AgentOffices from "./AgentOffices";
import AdminOverview from "./AdminOverview";
import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import BrokerTopSection from "../BrokerTopSection";
import { getObjLength } from "../../helpers/utils";
import BillingPaymentTab from "./BillingPaymentTab";
import logout from "../../../assets/icons/logout.png";
import CustomTabPanel from "../../common/CustomTabPanel";
import RealEstateAgent from "./RealEstateAgentProperties";
import SalesManagerOverview from "./SalesManagerOverview";
import SalesManagerREAgents from "./SalesManagerREAgents";
import AdminAccountSettings from "./AdminAccountSettings";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import SalesRepresentativesTab from "./SalesRepresentativesTab";
import BrokersPublicPageFooter from "../BrokersPublicPageFooter";
import { brokerAgentsAction } from "../../../redux/slices/brokers";
import { agentsAdminAction } from "../../../redux/slices/brokers/agentAdminSlice";

const AgentAdminTabContent = [
  {
    label: "Overview",
    value: 1,
    component: (base_route) => (
      <>
        <AdminOverview base_route={base_route} />
      </>
    ),
    tabInfoText:
      "Efficiently oversee your administrative profiles. In this section, you have the capability to add, remove, and modify the settings for each member of your team.",
  },
  {
    label: "Offices",
    value: 2,
    component: (base_route) => (
      <>
        <AgentOffices base_route={base_route} />
      </>
    ),
    tabInfoText:
      "Effectively manage your office profiles. Here, you can add, remove, and adjust the settings for each office within your organization.",
  },
  {
    label: "Users",
    value: 3,
    component: (base_route) => (
      <>
        <AgentsList base_route={base_route} />
      </>
    ),
    tabInfoText:
      "In this section, you can create, delete, and modify the profiles for each agent in your team.",
  },
];

const SalesManagerTabContent = [
  {
    label: "Overview",
    value: 1,
    component: (base_route) => (
      <>
        <SalesManagerOverview base_route={base_route} />
      </>
    ),
    tabInfoText:
      "Efficiently oversee your administrative profiles. In this section, you have the capability to add, remove, and modify the settings for each member of your team.",
  },
  {
    label: "Sales Representatives",
    value: 2,
    component: (base_route) => (
      <>
        <SalesRepresentativesTab base_route={base_route} />
      </>
    ),
    tabInfoText:
      "Efficiently oversee your administrative profiles. In this section, you have the capability to add, remove, and modify the settings for each member of your team.",
  },
  {
    label: "Real Estate Agents",
    value: 3,
    component: (base_route) => (
      <>
        <SalesManagerREAgents base_route={base_route} />
      </>
    ),
    tabInfoText:
      "In this section, you can create, delete, and modify the profiles for each agent in your team.",
  },
];

const RealEstateAgentTabContent = [
  {
    label: "Properties",
    value: 1,
    component: (base_route) => (
      <>
        <RealEstateAgent base_route={base_route} isREAgents={true} />
      </>
    ),
    tabInfoText: null,
  },
  {
    label: "Billing & Payment",
    value: 2,
    component: (base_route) => <BillingPaymentTab base_route={base_route} />,
    tabInfoText: null,
  },
];

const AgentAdmins = ({
  base_route = "broker",
  isAgentAdmin = false,
  isSalesManager = false,
  isREAgent = false,
}) => {
  const dispatch = useDispatch();
  const { broker } = useParams();
  const navigate = useNavigate();

  const [openAccountSettingModal, setOpenAccountSettingModal] = useState(false);

  const {
    createAdminLoader,
    createAgentAdminDetails,
    agentAdminLoader,
    agentProfileListData,
  } = useSelector((state) => state?.agentsAdmin);

  const handleAgentLogoutBtn = () => {
    localStorage.removeItem("agent_role");
    localStorage.removeItem("agentauthToken");
    localStorage.removeItem("agent_id");

    dispatch(brokerAgentsAction.resetBrokerAgentsState({}));
    dispatch(agentsAdminAction.resetAgentsAdminState({}));
    navigate(`/${base_route}/${broker}/agent-login`);
  };

  const closeAdminSettingsModal = () => {
    setOpenAccountSettingModal(false);
    dispatch(agentsAdminAction.fetchCreateAgentAdminData({}));
  };

  const onFormSubmit = (values) => {
    const data = { ...values };
    delete data?.profile_img;
    delete data?.office_id;
    if (data?.password?.length === 0) {
      delete data?.password;
      delete data?.old_password;
    }
    if (data?.logo === agentProfileListData?.data?.logo) {
      delete data?.logo;
    }
    console.log("Values...", data);

    dispatch(
      agentsAdminAction.createAgentAdminDetails({
        url: "broker/profile",
        data,
        brokertoken: true,
      })
    );
  };

  const agentProfileAPICall = () => {
    dispatch(
      agentsAdminAction.getAdminProfileData({
        url: `broker/profile`,
        data: {},
        brokertoken: true,
        source: base_route,
      })
    );
  };

  useEffect(() => {
    agentProfileAPICall();
  }, []);
  return (
    <>
      <div className="broker-create-account admin_wrapper">
        <BrokerTopSection
          hide_navbar={true}
          headerTexts={
            isAgentAdmin
              ? "Admin"
              : isSalesManager
              ? "Sales Manager"
              : "Real Estate Agent"
          }
          subHeaderTexts={
            isAgentAdmin
              ? "Welcome to the admin section! Here, you can update your details and manage both admins and agents."
              : isSalesManager
              ? "Page linked to a specific office"
              : "This is a dedicated admin page for a real estate agents"
          }
        />
        <section className="create_acc_form_wrapper mb-4">
          <Container fluid className="bg-white main-container pt-3">
            <div className="admin_header">
              <Button
                className="account_btn"
                onClick={() => {
                  setOpenAccountSettingModal(true);
                }}
              >
                <Avatar
                  src={agentProfileListData?.data?.logo}
                  alt={"agentProfile"}
                  className="agentProfile_img"
                  sx={{ width: 30, height: 30 }} // Adjust size as needed
                />
                Account settings
              </Button>
              <Button onClick={handleAgentLogoutBtn}>
                <span className="px-1">logout</span>{" "}
                <img src={logout} alt="logout" width={14} height={14} />{" "}
              </Button>
            </div>
            <CustomTabPanel
              tabContent={
                isAgentAdmin
                  ? AgentAdminTabContent
                  : isSalesManager
                  ? SalesManagerTabContent
                  : RealEstateAgentTabContent
              }
              base_route={base_route}
            />
          </Container>
        </section>
        <BrokersPublicPageFooter />
      </div>

      {/* Add Office form modal */}
      {openAccountSettingModal && (
        <DialogBox
          width={
            getObjLength(createAgentAdminDetails) &&
            createAgentAdminDetails?.success
              ? "sm"
              : "lg"
          }
          contentClassName={"bg-white p-0"}
          openPopup={openAccountSettingModal}
          setOpenPopup={setOpenAccountSettingModal}
          onClose={
            getObjLength(createAgentAdminDetails) &&
            createAgentAdminDetails?.success
              ? false
              : closeAdminSettingsModal
          }
          content={
            !getObjLength(createAgentAdminDetails) ||
            !createAgentAdminDetails?.success ? (
              <>
                <AdminAccountSettings
                  closeAgentAdminsModal={closeAdminSettingsModal}
                  onFormSubmit={onFormSubmit}
                  isEdit={true}
                  selectedProperty={agentProfileListData?.data ?? {}}
                  isAgentSettings={true}
                  uploadFileLabel="Logo"
                  uploadFileFormField="logo"
                />
              </>
            ) : (
              <PlainAlertMessage
                type="Success"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  agentProfileAPICall();
                  closeAdminSettingsModal();
                }}
                message={createAgentAdminDetails?.message}
                footer={false}
              />
            )
          }
        />
      )}

      <Loader open={agentAdminLoader || createAdminLoader} />
    </>
  );
};

export default AgentAdmins;
