import React, { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Cropper } from "react-cropper";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";

import FileUpload from "../../common/FileUpload";
import { getObjLength } from "../../helpers/utils";
import CustomTextField from "../../common/CustomTextField";
import { MenuProps } from "../../helpers/constants";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import add_photoIcon from "../../../assets/dashboard/add_photo_alternate.png";
import { closeDeleteIconStyle } from "../../admin/providers/CreateProvidersForm";

const fieldsValidations = (isAgentSettings = false) => {
  return Yup.object().shape({
    office_id: isAgentSettings
      ? Yup.string()
      : Yup.string("Please select the Office").required("Office is required"),

    first_name: Yup.string("Please enter your First Name.")
      .trim("The First name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name is required"),
    last_name: Yup.string("Please enter your Last name.")
      .trim("The Last name cannot include leading and trailing spaces")
      .strict(true)
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name is required"),

    email: Yup.string("Please enter your Email Id.")
      .email("Invalid email format")
      .max(255)
      .required("Email id is required"),

    phone: Yup.string("Please enter your Phone number.")
      .typeError("That doesn't look like a phone number")
      // .positive()
      .required("Phone number is required"),
    website: Yup.string()
      // .required("Website URL is required")
      .matches(
        /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w .-]*)*\/?$/,
        "Invalid URL pattern"
      ),
    old_password: !isAgentSettings
      ? Yup.string()
      : Yup.string("Please enter Password.")
          //  .required("Password is required")
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
          )
          .test(
            "no-spaces",
            "Password cannot contain any spaces",
            (value) => !/\s/.test(value)
          )
          .when("password", {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string().required("Old Password is required"),
          }),
    password: !isAgentSettings
      ? Yup.string()
      : Yup.string("Please enter Password.")
          //  .required("Password is required")
          .matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase,one lower, one number and one special case character"
          )
          .test(
            "no-spaces",
            "Password cannot contain any spaces",
            (value) => !/\s/.test(value)
          ),
  });
};
const AdminAccountSettings = ({
  isEdit = false,
  closeAgentAdminsModal = () => {},
  onFormSubmit = () => {},
  selectedProperty = {},
  isAgentSettings = false,
  uploadFileLabel = "Photo",
  uploadFileFormField = "profile_img",
}) => {
  const signUpFormRef = useRef();
  const cropperProfileRef = useRef();
  const profileDropzoneRef = useRef();

  const [croppedProfileImage, setCroppedProfileImage] = useState(null);
  const [selectedProfileFile, setSelectedProfileFile] = useState(null);
  const [profileLoader, setProfileLoader] = useState(false); // State for loader
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { createAgentAdminDetails } = useSelector(
    (state) => state?.agentsAdmin
  );
  const { brokerDetails } = useSelector((state) => state?.brokerAgents);

  // when user select the image
  const onFileChange = (files, type) => {
    const reader = new FileReader();
    reader.onload = () => {
      setCroppedProfileImage(null);
      setSelectedProfileFile(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };
  // get cropped image
  const getCropData = (filedName) => {
    // Start loading

    setProfileLoader(true);

    setTimeout(() => {
      // Simulate processing delay

      const croppedData = cropperProfileRef.current?.cropper
        .getCroppedCanvas()
        .toDataURL();
      setCroppedProfileImage(croppedData);
      setSelectedProfileFile(null);
      signUpFormRef?.current?.setFieldValue(filedName, croppedData);

      // Stop loading after cropping is done
      setProfileLoader(false);
    }, 500); // Simulated processing delay (adjust if needed)
  };

  const triggerFileUpload = (type = "profile") => {
    setTimeout(() => {
      setSelectedProfileFile(null);
    }, 500);
    const fileInput =
      profileDropzoneRef.current?.querySelector('input[type="file"]');
    if (fileInput) fileInput.click();
  };

  const customFileUploadInnerSection = (getInputProps, getRootProps) => {
    return (
      <div
        className="dz-message needsclick custom-dropzone"
        style={{ padding: "16px" }}
        {...getRootProps()}
      >
        <div
          className="dz-message needsclick custom-dropzone"
          style={{ padding: "16px" }}
        >
          <input {...getInputProps()} name="file" />
          <i className="d-inline-block">
            <img src={add_photoIcon} alt="add_photoIcon" />
          </i>
        </div>
      </div>
    );
  };

  const handleClickShowPassword = (setState) => setState((show) => !show);

  useEffect(() => {
    if (isEdit) {
      setCroppedProfileImage(selectedProperty?.[uploadFileFormField]);
    } else {
      setCroppedProfileImage(null);
    }
  }, [isEdit]);

  const officeList = brokerDetails?.success
    ? brokerDetails?.data?.broker?.offices
    : [];

  const agentAdminValidationSchema = fieldsValidations(isAgentSettings);
  return (
    <>
      {" "}
      <div className="broker-create-account agent_admin_form_modal">
        <Box className="create_acc_form_wrapper mt-0">
          <Box className="box-container agents_form agent_admin_form_modal">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                office_id: selectedProperty?.office_id || "",
                first_name: selectedProperty?.first_name || "",
                last_name: selectedProperty?.last_name || "",
                email: selectedProperty?.email || "",
                phone:
                  selectedProperty?.phone ||
                  selectedProperty?.phone_number ||
                  "",
                website: selectedProperty?.website || "",
                profile_img: selectedProperty?.profile_img || "",
                password: "",
                old_password: "",
              }}
              validationSchema={agentAdminValidationSchema}
              innerRef={signUpFormRef}
              onSubmit={(values) => {
                if (!isValidPhoneNumber(values?.phone) && !isAgentSettings) {
                  return;
                }
                onFormSubmit(values);
              }}
            >
              {({ values, setFieldValue, touched, errors, initialValues }) => {
                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <Grid item xs={12} md={12}>
                          <InputLabel shrink htmlFor="bootstrap-input">
                            {uploadFileLabel}
                          </InputLabel>
                          {/* When cropper will crop the image then we will have data in the croppedProfileImage state so code will transfer to else condition */}
                          {!croppedProfileImage ? (
                            selectedProfileFile ? (
                              <>
                                {/* When select the image then this cropper will be displayed */}
                                <Cropper
                                  ref={cropperProfileRef}
                                  style={{ height: "150px", width: "150px" }}
                                  zoomTo={0}
                                  initialAspectRatio={1}
                                  src={selectedProfileFile}
                                  viewMode={1}
                                  minCropBoxHeight={10}
                                  minCropBoxWidth={10}
                                  background={false}
                                  responsive={true}
                                  autoCropArea={1}
                                  checkOrientation={false}
                                  guides={false}
                                />
                                <Box
                                  className="d-flex justify-content-start"
                                  mt={2}
                                >
                                  <Button
                                    onClick={() =>
                                      getCropData(uploadFileFormField)
                                    }
                                    variant="contained"
                                    className="success-btn"
                                    style={{ padding: "6px 16px" }}
                                    // color="primary"
                                  >
                                    {profileLoader ? (
                                      <>
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                        &nbsp;&nbsp;Processing..
                                      </>
                                    ) : (
                                      `Crop ${uploadFileLabel}`
                                    )}
                                  </Button>
                                </Box>
                              </>
                            ) : (
                              <>
                                {/* Initially there is neither cropped image nor selected image so upload image field will show */}
                                <span className="servicer-signup-form">
                                  <FileUpload
                                    name="upload_doc"
                                    accept={{
                                      "image/png": [".png", ".jpeg", ".jpg"],
                                    }}
                                    multiple={false}
                                    onFileUpload={async (file) => {
                                      onFileChange(file, "profile");
                                    }}
                                    maxSize={10}
                                    dragDropText={`Drag and drop Logo or `}
                                    greenText="Upload a Logo"
                                    subText="Only .png, .jpeg, .jpg files are allowed"
                                    customFileUploadInnerSection={
                                      customFileUploadInnerSection
                                    }
                                  />
                                </span>
                              </>
                            )
                          ) : (
                            <>
                              {/* After successfully cropping it will display the cropped image and delete icon for clearing the image */}
                              <Box
                                className="position-relative p-4 rounded d-flex justify-content-center align-items-center"
                                sx={{
                                  backgroundColor: "#F6F9FC",
                                  border: croppedProfileImage
                                    ? "1px solid #E2E8EB"
                                    : "",
                                  width: "150px",
                                  height: "150px",
                                }}
                              >
                                <img
                                  height="150px"
                                  width="150px"
                                  src={croppedProfileImage}
                                  // style={{
                                  //   minHeight: "150px",
                                  //   maxHeight: "150px",
                                  // }}
                                  alt="cropped"
                                  id="croppedProfileImage"
                                  className="rounded"
                                />

                                <IconButton
                                  sx={{
                                    ...closeDeleteIconStyle,
                                    color: "#FC2125",
                                    fontSize: "1.4rem",
                                  }}
                                  onClick={() => {
                                    setCroppedProfileImage(null);
                                    setSelectedProfileFile(null);
                                    signUpFormRef?.current?.setFieldValue(
                                      uploadFileFormField,
                                      ""
                                    );
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </>
                          )}
                          {/* //  {isSubmit && showLogoError === 0 && ( */}
                          {touched?.[uploadFileFormField] &&
                            Boolean(errors?.[uploadFileFormField]) && (
                              <FormHelperText
                                sx={{
                                  color: "#d32f2f !important",
                                  m: "0 !important",
                                  fontSize: "12px !important",
                                }}
                              >
                                Please add your profile picture
                              </FormHelperText>
                            )}
                          {isEdit ? (
                            <>
                              {!selectedProfileFile && (
                                <Button
                                  variant="outlined"
                                  className="mt-2"
                                  onClick={() => triggerFileUpload("profile")}
                                >
                                  Edit <EditOutlinedIcon fontSize="small" />
                                </Button>
                              )}

                              {/* Dropzone mounted, just hidden if image exists */}
                              <div
                                ref={profileDropzoneRef}
                                style={{ display: "none" }}
                              >
                                <FileUpload
                                  name="upload_doc"
                                  accept={{
                                    "image/png": [".png", ".jpeg", ".jpg"],
                                  }}
                                  multiple={false}
                                  onFileUpload={(file) =>
                                    onFileChange(file, "profile")
                                  }
                                  onFileDialogCancel={() => {
                                    setSelectedProfileFile(null);
                                  }}
                                  maxSize={10}
                                />
                              </div>
                            </>
                          ) : (
                            <p className="files_texts">
                              Click Here to Upload your Headshot that will
                              appear on all emails.
                            </p>
                          )}
                          <FormHelperText>
                            {touched.upload_doc && errors.upload_doc && (
                              <Typography
                                className="text-danger"
                                sx={{ fontSize: "0.8rem" }}
                              >
                                {errors.upload_doc}
                              </Typography>
                            )}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6} px={1} pb={1}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                              First name
                            </InputLabel>
                            <CustomTextField
                              name="first_name"
                              validation={{
                                values,
                                setFieldValue,
                                touched,
                                errors,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={1} pb={1}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                              Last name
                            </InputLabel>
                            <CustomTextField
                              name="last_name"
                              validation={{
                                values,
                                setFieldValue,
                                touched,
                                errors,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={1} pb={1}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                              Email
                            </InputLabel>
                            <CustomTextField
                              name="email"
                              disabled={isAgentSettings}
                              validation={{
                                values,
                                setFieldValue,
                                touched,
                                errors,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6} px={1} pb={1}>
                            <InputLabel shrink htmlFor="bootstrap-input">
                              Mobile number
                            </InputLabel>
                            <CustomPhoneNumberInput
                              name="phone"
                              placeholder={null}
                              values={values}
                              disabled={isAgentSettings}
                              edit={!isAgentSettings}
                              touched={touched}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              otherProps={{
                                onChange: (event) => {
                                  setFieldValue("phone", event);
                                  if (!event) {
                                    setFieldValue("phone", "");
                                  }
                                },
                              }}
                            />
                          </Grid>
                          {!isAgentSettings && (
                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <InputLabel
                                shrink
                                style={{ color: "#00000075" }}
                                label="Office"
                              >
                                Office Name
                              </InputLabel>
                              <FormControl
                                fullWidth
                                className="sign-textField text-start"
                                error={
                                  touched.office_id && Boolean(errors.office_id)
                                }
                              >
                                <Select
                                  labelId="demo-simple-select-placeholder"
                                  id="demo-simple-select"
                                  value={values?.office_id}
                                  label="Choose your Title/Escrow Office"
                                  name="office_id"
                                  variant="outlined"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "office_id",
                                      event.target.value
                                    );
                                  }}
                                  MenuProps={MenuProps}
                                  displayEmpty
                                >
                                  {officeList?.map((offices, i) => (
                                    <MenuItem value={offices?.id} key={i}>
                                      {offices?.office_name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.office_id &&
                                  Boolean(errors.office_id) && (
                                    <FormHelperText sx={{ color: "#d32f2f" }}>
                                      {touched.office_id && errors.office_id}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          )}
                          {!isAgentSettings && (
                            <Grid item xs={12} md={6} px={1} pb={1}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Website
                              </InputLabel>
                              <CustomTextField
                                name="website"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                              />
                            </Grid>
                          )}

                          {isAgentSettings && (
                            <>
                              <Grid item xs={12} md={6} px={1} pb={1}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                  Enter old password
                                </InputLabel>
                                <OutlinedInput
                                  className="textfield"
                                  type={showPassword ? "text" : "password"}
                                  name="old_password"
                                  sx={{ backgroundColor: "#f6f9fc" }}
                                  inputProps={{
                                    autoComplete: "new-confirm_password",
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle confirm_password visibility"
                                        onClick={() =>
                                          handleClickShowPassword(
                                            setShowPassword
                                          )
                                        }
                                        onMouseDown={(event) =>
                                          event.preventDefault()
                                        }
                                        edge="end"
                                      >
                                        {showPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  value={values?.old_password}
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "old_password",
                                      event.target.value?.trim()
                                    );
                                    // setPasswordcopy(event.target.value.trim());
                                  }}
                                  helperText={
                                    touched.old_password && errors.old_password
                                  }
                                  error={
                                    touched.old_password &&
                                    Boolean(errors.old_password)
                                  }
                                />
                                {touched.old_password &&
                                  Boolean(errors.old_password) && (
                                    <FormHelperText
                                      sx={{
                                        color: "rgb(211, 47, 47) !important",
                                        fontSize: "12px !important",
                                        m: "0 !important",
                                        maxWidth: "100% !important",
                                      }}
                                    >
                                      {touched.old_password &&
                                        errors.old_password}
                                    </FormHelperText>
                                  )}
                              </Grid>
                              <Grid item xs={12} md={6} px={1} pb={1}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                  Create a new password
                                </InputLabel>
                                <OutlinedInput
                                  className="textfield"
                                  type={
                                    showConfirmPassword ? "text" : "password"
                                  }
                                  name="password"
                                  sx={{ backgroundColor: "#f6f9fc" }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle confirm_password visibility"
                                        onClick={() =>
                                          handleClickShowPassword(
                                            setShowConfirmPassword
                                          )
                                        }
                                        onMouseDown={(event) =>
                                          event.preventDefault()
                                        }
                                        edge="end"
                                      >
                                        {showConfirmPassword ? (
                                          <VisibilityOff />
                                        ) : (
                                          <Visibility />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                  value={values?.password}
                                  fullWidth
                                  onChange={(event) => {
                                    setFieldValue(
                                      "password",
                                      event.target.value?.trim()
                                    );
                                    // setPasswordcopy(event.target.value.trim());
                                  }}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                  error={
                                    touched.password && Boolean(errors.password)
                                  }
                                />
                                {touched.password &&
                                  Boolean(errors.password) && (
                                    <FormHelperText
                                      sx={{
                                        color: "rgb(211, 47, 47) !important",
                                        fontSize: "12px !important",
                                        m: "0 !important",
                                        maxWidth: "100% !important",
                                      }}
                                    >
                                      {touched.password && errors.password}
                                    </FormHelperText>
                                  )}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    {getObjLength(createAgentAdminDetails) &&
                      !createAgentAdminDetails?.success && (
                        <p className="fw-500 terms_error w-100 text-danger text-center">
                          {createAgentAdminDetails?.data?.message}
                        </p>
                      )}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      px={1}
                      className="d-flex justify-content-between align-items-center mt-3"
                    >
                      <Button
                        onClick={closeAgentAdminsModal}
                        className="popup_Btn other_popup_btn"
                        variant="contained"
                      >
                        Discard
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        className={`popup_Btn success_popup_btn  ${
                          JSON.stringify(initialValues) ===
                            JSON.stringify(values) && "cursor-disable"
                        }`}
                        disabled={
                          JSON.stringify(initialValues) ===
                          JSON.stringify(values)
                        }
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default AdminAccountSettings;
