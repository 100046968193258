import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Navbar, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";

import { decryptPayload } from "../helpers/utils";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import remax_logo_wht from "../../assets/dashboard/remax_logo_white.png";
import {
  ATTORNEY_SUPPORT_PHONE,
  ATTORNEY_SUPPORT_PHONE_FORMAT,
  ROLES,
} from "../helpers/constants";

const BrokerTopSection = ({
  headerTexts,
  subHeaderTexts,
  hide_my_acc_btn = false,
  logout_btn = false,
  hide_navbar = false,
  base_route = "broker",
}) => {
  const { broker } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { agentProfileData, brokerDetails } = useSelector(
    (state) => state?.brokerAgents
  );
  const loggedInUserRole = decryptPayload(localStorage?.getItem("agent_role"));

  const handleAgentAccountBtn = () => {
    if (localStorage.getItem("agentauthToken")) {
      if (
        loggedInUserRole === ROLES?.brokerAdmin ||
        loggedInUserRole === ROLES.titleAdmin
      ) {
        navigate(`/${base_route}/${broker}/agent-admin`);
      } else {
        navigate(`/${base_route}/${broker}/agent-proeprties`);
      }
    } else {
      navigate(`/${base_route}/${broker}/agent-login`);
    }
  };

  const handleAgentLogoutBtn = () => {
    localStorage.removeItem("agentauthToken");
    localStorage.removeItem("agent_id");

    dispatch(brokerAgentsAction.resetBrokerAgentsState({}));
    navigate(`/${base_route}/${broker}/agent-login`);
  };

  return (
    <>
      <section
        className="background_img"
        style={{
          backgroundImage: `url(${brokerDetails?.data?.broker?.common_bg})`,
        }}
      >
        {!hide_navbar && (
          <Navbar.Brand className="broker_navbar">
            <Link to={`/${base_route}/${broker}`} className="">
              <img
                className="agent_logo"
                src={brokerDetails?.data?.broker?.logo_2}
                width="220px"
                height="35px"
                alt="logo"
              />
              <img
                src={remax_logo_wht}
                width="150px"
                height="35px"
                alt="logo"
              />
            </Link>
            <div>
              <a href={`tel:${ATTORNEY_SUPPORT_PHONE}`}>
                <Button
                  className="call_us_btn"
                  variant="link"
                  // onClick={handleMyAccountBtn}
                >
                  Call us now at {ATTORNEY_SUPPORT_PHONE_FORMAT}
                </Button>
              </a>

              {!hide_my_acc_btn && (
                <Button
                  className="success_popup_btn call_us_btn"
                  variant="link"
                  onClick={handleAgentAccountBtn}
                >
                  My Account
                </Button>
              )}
              {logout_btn && (
                <Button
                  className="error_popup_btn call_us_btn"
                  variant="link"
                  onClick={handleAgentLogoutBtn}
                >
                  Sign out
                </Button>
              )}
            </div>
            {/* <NavBar
            isInvestors={false}
            custom_logo={remax_logo_blk}
            custom_logo_white={remax_logo_wht}
            isLandingPage={false}
            hide_my_acc_btn={true}
            isNavLinkLeftAlign={true}
            hideServiceProviderFromNavbar={true}
            textWhiteServiceProvider={"text-white"}
          /> */}
          </Navbar.Brand>
        )}
        <div className=" create_acc_section">
          <Container fluid className="align-items-center text-white">
            <Row className="mt-2 mt-sm-2 justify-content-center text-center">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={10}
                xl={10}
                className="content-spacing"
              >
                <h2 className="main-text mt-2">{headerTexts}</h2>
                <p className="paragraph_texts fw-400 mt-2 mx-auto">
                  {subHeaderTexts}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default BrokerTopSection;
