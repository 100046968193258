import CryptoJS from "crypto-js";

import { loadStripe } from "@stripe/stripe-js";

export const PROFESSIONAL_TYPE = {
  lender: "Lender",
  broker: "Broker",
  provider: "Provider",
  other: "Other",
};

export const PROVIDERS_TYPE = {
  attorney: "Attorney",
  title: "Title",
  escrow_Settlement: "Escrow/Settlement",
  otherServiceProvider: "Other Service Provider",
};

export const MAX_FILE_SIZE = 100;

export const ROLES = {
  admin: "Super Admin",
  subAdmin: "Admin",
  customer: "Customer",
  brokerAdmin: "Broker Admin",
  titleAdmin: "Title Admin",
  professional: "Professional",
  serviceProvider: "Service Provider",
  secondaryUser: "Sub User",
  lennar: "Lennar",
  provider: "Provider",
  countyRecorder: "County Recorder",
};

export const ROLE_CODES = {
  admin: 1,
  subAdmin: 11,
  customer: 21,
  professional: 31,
  serviceProvider: 41,
  secondaryUser: 51,
  lennar: 61,
  provider: 71,
  countyRecorder: 81,
};

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

// record per page in admin tabels
export const PER_PAGE = 10;
export const MODAL_PAGE = 5;

export const resendOTPSeconds = 119;

export const UCC_FILLER_NAME = "EquityProtect Inc.";
export const UCC_FILLER_PHONE = "+18882132959";
export const UCC_FILLER_PHONE_FORMAT = "(888) 213-2959";
export const UCC_FILLER_EMAIL = "Recordings@equityprotect.com";
export const UCC_SECURED_PARTY_ORGANIZATION_NAME = "EquityProtect Inc.";
export const UCC_SECURED_PARTY_ADDRESS = "5566 Longley Ln Suite B";
export const UCC_SECURED_PARTY_CITY = "Reno";
export const UCC_SECURED_PARTY_STATE = "Nv";
export const UCC_SECURED_PARTY_POSTAL_CODE = "89511";

export const LOCAL_RECORDING_FEES = 125;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const FieldStyle = {
  borderRadius: "3px",
};

export const REQUEST_ACCESS_ACTION = [
  // { id: 1, label: "Waiver - Refinance", value: "waiver" },
  { id: 2, label: "Termination - Sale", value: "termination" },
  // { id: 3, label: "Restriction", value: "restriction" },
];

export const REQUESTED_DOCUMENT_STATUS = [
  { id: 1, label: "Requested", value: "requested" },
  { id: 2, label: "Accepted", value: "accepted" },
  { id: 2, label: "Rejected", value: "rejected" },
  { id: 2, label: "Completed", value: "completed" },
  { id: 3, label: "Cancelled", value: "cancelled" },
  // { id: 3, label: "Restriction", value: "restriction" },
];

export const jsonLastStepOptions = [
  { value: "SEND_OTP", label: "SEND OTP" },
  { value: "VERIFY_PHONE_OTP", label: "VERIFY PHONE OTP" },
  { value: "VERIFY_EMAIL_OTP", label: "VERIFY EMAIL OTP" },
  { value: "GENERATE_QUESTIONS", label: "GENERATE QUESTIONS" },
  { value: "VERIFY_ANSWERS", label: "VERIFY ANSWERS" },
  { value: "GET_MAILING_ADDRESS", label: "GET MAILING ADDRESS" },
  { value: "SAVE_CREDIT_CARD", label: "SAVE CREDIT CARD" },
  { value: "PAYMENT", label: "PAYMENT" },
  { value: "SECURITY_PIN", label: "SECURITY PIN" },
  { value: "REGISTER_FACE", label: "REGISTER FACE" },
  { value: "REGISTER_SIGNATURE", label: "REGISTER SIGNATURE" },
  { value: "SAVE_BENEFICIARIES", label: "SAVE BENEFICIARIES" },
  { value: "SEND_WELCOME_EMAIL", label: "SEND WELCOME EMAIL" },
];

export const jsonLastSteps = {
  SEND_OTP: "SEND OTP",
  VERIFY_PHONE_OTP: "VERIFY PHONE OTP",
  VERIFY_EMAIL_OTP: "VERIFY EMAIL OTP",
  GENERATE_QUESTIONS: "GENERATE QUESTIONS",
  VERIFY_ANSWERS: "VERIFY ANSWERS",
  GET_MAILING_ADDRESS: "GET MAILING ADDRESS",
  SAVE_CREDIT_CARD: "SAVE CREDIT CARD",
  PAYMENT: "PAYMENT",
  SECURITY_PIN: "SECURITY PIN",
  REGISTER_FACE: "REGISTER FACE",
  REGISTER_SIGNATURE: "REGISTER SIGNATURE",
  SAVE_BENEFICIARIES: "SAVE BENEFICIARIES",
  SEND_WELCOME_EMAIL: "SEND WELCOME EMAIL",
};

export const SUBDOMAIN = {
  equityprotect: "equityprotect",
  servicer: "servicer",
  professional: "professional",
  try: "try",
  provider: "provider",
};

export const servicerProvidersjsonLastStepOptions = [
  { value: "SEND_OTP", label: "SEND OTP" },
  { value: "VERIFY_PHONE_OTP", label: "VERIFY PHONE OTP" },
  { value: "VERIFY_EMAIL_OTP", label: "VERIFY EMAIL OTP" },
  { value: "SAVE_PROFESSIONAL_DETAIL", label: "SAVE PROFESSIONAL DETAIL" },
  { value: "REGISTER_FACE", label: "REGISTER FACE" },
  { value: "REGISTER_SIGNATURE", label: "REGISTER SIGNATURE" },
  { value: "SEND_WELCOME_EMAIL", label: "SEND WELCOME EMAIL" },
];

export const CALENDLY_MEET_URL = "https://calendly.com/jon-ep2018/30min";
// https://calendly.com/ashish-thakor-crest/lead

export const playStoreURL = "https://play.google.com/store/apps";
export const appStoreURL =
  "https://apps.apple.com/es/app/equityprotect/id6446863228";

// to sort the states
// console.log(
//   "sorted array",
//   USA_STATES.sort((state1, state2) => state1.code.localeCompare(state2.code))
// );

// states of the USA
export const USA_STATES = [
  { name: "Alaska", code: "AK" },
  { name: "Alabama", code: "AL" },
  { name: "Arkansas", code: "AR" },
  { name: "Arizona", code: "AZ" },
  { name: "California", code: "CA" },
  { name: "Colorado", code: "CO" },
  { name: "Connecticut", code: "CT" },
  { name: "Delaware", code: "DE" },
  { name: "District of Columbia", code: "DC" },
  { name: "Florida", code: "FL" },
  { name: "Georgia", code: "GA" },
  { name: "Hawaii", code: "HI" },
  { name: "Iowa", code: "IA" },
  { name: "Idaho", code: "ID" },
  { name: "Illinois", code: "IL" },
  { name: "Indiana", code: "IN" },
  { name: "Kansas", code: "KS" },
  { name: "Kentucky", code: "KY" },
  { name: "Louisiana", code: "LA" },
  { name: "Massachusetts", code: "MA" },
  { name: "Maryland", code: "MD" },
  { name: "Maine", code: "ME" },
  { name: "Michigan", code: "MI" },
  { name: "Minnesota", code: "MN" },
  { name: "Missouri", code: "MO" },
  { name: "Mississippi", code: "MS" },
  { name: "Montana", code: "MT" },
  { name: "North Carolina", code: "NC" },
  { name: "North Dakota", code: "ND" },
  { name: "Nebraska", code: "NE" },
  { name: "New Hampshire", code: "NH" },
  { name: "New Jersey", code: "NJ" },
  { name: "New Mexico", code: "NM" },
  { name: "Nevada", code: "NV" },
  { name: "New York", code: "NY" },
  { name: "Ohio", code: "OH" },
  { name: "Oklahoma", code: "OK" },
  { name: "Oregon", code: "OR" },
  { name: "Pennsylvania", code: "PA" },
  { name: "Rhode Island", code: "RI" },
  { name: "South Carolina", code: "SC" },
  { name: "South Dakota", code: "SD" },
  { name: "Tennessee", code: "TN" },
  { name: "Texas", code: "TX" },
  { name: "Utah", code: "UT" },
  { name: "Virginia", code: "VA" },
  { name: "Vermont", code: "VT" },
  { name: "Washington", code: "WA" },
  { name: "Wisconsin", code: "WI" },
  { name: "West Virginia", code: "WV" },
  { name: "Wyoming", code: "WY" },
];

export const AddressVerificationColumns = [
  {
    id: "no",
    disablePadding: true,
    label: "No.",
  },
  {
    id: "mailingaddress",
    disablePadding: true,
    label: "Property Address",
  },
  {
    id: "postcard",
    disablePadding: true,
    label: "Verify Postcard",
  },
  {
    id: "status",
    disablePadding: true,
    label: "Status",
  },
];

// provider type like lennar/acpc etc.
export const PROVIDERSTYPE = {
  lennar: "lennar",
  acpc: "acpc",
};

// ====================ENV====================

// country code
export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;

// google map API key
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

// support information
export const SUPPORT_EMAIL = process.env.REACT_APP_SUPPORT_EMAIL;
export const SUPPORT_PHONE = process.env.REACT_APP_SUPPORT_PHONE;
export const SUPPORT_PHONE_FORMAT = process.env.REACT_APP_SUPPORT_PHONE_FORMAT;
export const EP_ADDRESS = process.env.REACT_APP_EP_ADDRESS;
export const ATTORNEY_SUPPORT_PHONE =
  process.env.REACT_APP_ATTORNEY_SUPPORT_PHONE;
export const ATTORNEY_SUPPORT_PHONE_FORMAT =
  process.env.REACT_APP_ATTORNEY_SUPPORT_PHONE_FORMAT;
export const BLOG_SUPPORT_PHONE = process.env.REACT_APP_BLOG_SUPPORT_PHONE;
export const BLOG_SUPPORT_PHONE_FORMAT =
  process.env.REACT_APP_BLOG_SUPPORT_PHONE_FORMAT;
export const PROPERTY_RISK_SUPPORT_PHONE =
  process.env.REACT_APP_PROPERTY_RISK_SUPPORT_PHONE;
export const PROPERTY_RISK_SUPPORT_PHONE_FORMAT =
  process.env.REACT_APP_PROPERTY_RISK_SUPPORT_PHONE_FORMAT;

// base url: BE local/staging/production
export const BASE_URL = process.env.REACT_APP_API_URL;
// export const BASE_URL = "https://staging.equityprotect.com/api/v1/";

// base url:serivce provider
export const SERVICE_PROVIDERS_URL =
  process.env.REACT_APP_SERVICE_PROVIDERS_URL;

// base url:other 3rd party provider
export const OTHER_PROVIDERS_URL = process.env.REACT_APP_OTHER_PROVIDERS_URL;

// base url:custom domain url for provider
export const CUSTOM_DOMAIN_URL = process.env.REACT_APP_CUSTOM_DOMAIN_URL;

// Server IP
export const SERVER_IP = process.env.REACT_APP_SERVER_IP;

// FE local/staging/production
export const DEFAULT_URL = process.env.REACT_APP_DEFAULT_URL;

// try domin
export const TRY_DOMAIN_URL = process.env.REACT_APP_TRY_DOMAIN_URL;

// try domin
export const COUNTY_URL = process.env.REACT_APP_COUNTY_URL;

// local || staging || production
export const SERVER = process.env.REACT_APP_SERVER;

// stripe key
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const stripePromise = loadStripe(STRIPE_KEY);

// recaptcha site key
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

//for encryption and decryption
export const secretKey = CryptoJS.enc.Hex.parse(
  process.env.REACT_APP_ENCRYPTION_SECRET_KEY
); // 128-bit key;

export const secretKeyEncrypt = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;

// Initialization Vector value
export const iv = CryptoJS.enc.Hex.parse(
  process.env.REACT_APP_ENCRYPTION_VECTOR_KEY
); // Random 128-bit IV

// zendesk secret key
export const ZENDESK_SECRET_KEY = process.env.REACT_APP_ZENDESK_SECRET_KEY;

// pusher credentials
export const BACKEND_URL = process.env.REACT_APP_API_URL;
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = process.env.REACT_APP_PUSHER_APP_CLUSTER;

// social media links
export const INSTAGRAM_LINK = process.env.REACT_APP_INSTAGRAM_LINK;
export const FACEBOOK_LINK = process.env.REACT_APP_FACEBOOK_LINK;

// Better Business Bureau links
export const BBB_REVIEW_LINK = process.env.REACT_APP_BBB_REVIEW_LINK;

//Agent's Signup terms and conditions
export const AGENTS_TERMS_LINK = process.env.REACT_APP_AGENTS_TERMS_LINK;

// Marketing Lead Gen ID for Signup form
export const SIGNUP_MARKETING_AUTOMATION_ID =
  process.env.REACT_APP_SIGNUP_MARKETING_AUTOMATION_ID;

// Marketing Lead Gen ID for Property Risk form
export const MARKETING_AUTOMATION_ID =
  process.env.REACT_APP_MARKETING_AUTOMATION_ID;
export const MIKE_MARKETING_AUTOMATION_ID =
  process.env.REACT_APP_MARKETING_MIKE_AUTOMATION_ID;
export const GORKA_MARKETING_AUTOMATION_ID =
  process.env.REACT_APP_MARKETING_GORKA_AUTOMATION_ID;
export const PRAGER_MARKETING_AUTOMATION_ID =
  process.env.REACT_APP_MARKETING_PRAGER_AUTOMATION_ID;

//National Hosts Promocode
export const Mike30 = process.env.REACT_APP_RADIO_Mike30;
export const Gorka30 = process.env.REACT_APP_RADIO_Gorka30;
export const Prager30 = process.env.REACT_APP_RADIO_Prager30;

// allowed email address for export Payment CSV
export const danEmail = process.env.REACT_APP_DAN_EP;
// allowed email address for Generate/Upload UCC
export const uccAdmin = process.env.REACT_APP_UCC_ADMIN;

// To find the time zone of the user's browser
export const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
