import React, { useCallback, useEffect, useState } from "react";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";

import FormControl from "@mui/material/FormControl";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import { getObjLength } from "../../helpers/utils";
import CustomTextField from "../../common/CustomTextField";
import CommonAgentsAdminTable from "./CommonAgentsAdminTable";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { MenuProps, PER_PAGE, USA_STATES } from "../../helpers/constants";
import CustomPhoneNumberInput from "../../common/CustomPhoneNumberInput";
import { agentsAdminAction } from "../../../redux/slices/brokers/agentAdminSlice";

import "../BrokerSignupPage.scss";

const columns = [
  {
    key: 1,
    label: "",
    id: "action",
    minWidth: 10,
  },
  {
    key: 2,
    label: "Office name",
    id: "office_name",
    width: 120,
  },
  {
    key: 3,
    label: "Address",
    id: "address",
    maxWidth: 80,
  },
  {
    key: 4,
    label: "City",
    id: "city",
    minWidth: 30,
  },
  {
    key: 5,
    label: "State",
    id: "state",
    minWidth: 30,
  },
  {
    key: 6,
    label: "Zip",
    id: "zip",
    minWidth: 50,
  },
  {
    key: 7,
    label: "Phone number",
    id: "phone",
    width: 150,
  },
  {
    key: 8,
    label: "Website",
    id: "website",
    width: 150,
  },
];

const agentOfficesValidationSchema = Yup.object().shape({
  office_name: Yup.string("Please enter your First Name.")
    .trim("The First name cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("First Name is required"),

  address: Yup.string("Please enter your Address.")
    .trim("The Address cannot include leading and trailing spaces")
    .strict(true)
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("Address is required"),

  city: Yup.string("Please enter your City.")
    .trim("The city cannot include leading and trailing spaces")
    .strict(true)
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("City is required"),
  state: Yup.string("Please select the state").required("State is required"),

  zip: Yup.string("Please enter your Zip code.")
    .required("Zip is required")
    .matches(/^[0-9]{5}$/, "Zipcode must be exactly 5 digits"),
  // .min(6, "Zip needs 6 digits")
  // .max(6, "Zip needs 6 digits"),
  phone: Yup.string("Please enter your Phone number.")
    .typeError("That doesn't look like a phone number")
    // .positive()
    .required("Phone number is required"),
  website: Yup.string()
    // .required("Website URL is required")
    .matches(
      /^(https?:\/\/)?([\da-zA-Z.-]+)\.([a-zA-Z.]{2,6})([/\w .-]*)*\/?$/,
      "Invalid URL pattern"
    ),
});

const AgentOffices = ({ base_route = "broker" }) => {
  const dispatch = useDispatch();
  console.log("base_route==>", base_route);

  const [agentOfficeModalOpen, setAgentOfficeModalOpen] = useState(false);
  const [isEditAgentOffice, setIsEditAgentOffice] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});

  const [payload, setPayload] = useState({
    limit: 10,
    offset: 0,
  });
  const [pageCount, setPageCount] = useState(0);

  const { agentAdminLoader, createAgentOfficeDetails, agentOfficeDetails } =
    useSelector((state) => state?.agentsAdmin);
  const { brokerDetails } = useSelector((state) => state?.brokerAgents);

  const openAddOfficeModal = () => {
    setSelectedProperty();
    setAgentOfficeModalOpen(true);
  };
  const closeAddOfficeModal = () => {
    setAgentOfficeModalOpen(false);
    setSelectedProperty();
    setIsEditAgentOffice(false);
    dispatch(agentsAdminAction.fetchCreateAgentOfficeData({}));
  };

  const handleEditOfficeModal = () => {
    setAgentOfficeModalOpen(true);
    setIsEditAgentOffice(true);
    dispatch(agentsAdminAction.fetchCreateAgentOfficeData({}));
  };

  const onSubmit = (values) => {
    console.log("Values...", values);
    const data = { ...values, broker_id: brokerDetails?.data?.broker?.id };
    let url = `broker/create-office`;
    if (isEditAgentOffice) {
      url = `broker/update-office/${selectedProperty?.id}`;
    }
    dispatch(
      agentsAdminAction.createAgentOfficeDetails({
        url,
        data,
        brokertoken: true,
      })
    );
  };

  const agentOfficesListAPICall = useCallback(() => {
    let data = {
      limit: payload?.limit,
      offset: payload?.offset,
      broker_id: brokerDetails?.data?.broker?.id,
    };
    dispatch(
      agentsAdminAction.getAgentOfficeDetails({
        url: `broker/get-offices`,
        data,
        brokertoken: true,
      })
    );
  }, [dispatch, payload?.limit, payload?.offset]);

  useEffect(() => {
    agentOfficesListAPICall();
  }, [agentOfficesListAPICall]);

  useEffect(() => {
    if (
      getObjLength(createAgentOfficeDetails) &&
      createAgentOfficeDetails?.success
    ) {
      agentOfficesListAPICall();
    }
  }, [createAgentOfficeDetails]);

  useEffect(() => {
    if (getObjLength(agentOfficeDetails)) {
      if (agentOfficeDetails?.success) {
        if (pageCount === 0)
          setPageCount(Math.ceil(agentOfficeDetails?.count / PER_PAGE));
        if (agentOfficeDetails?.count === 10) {
          setPayload((prevState) => ({
            ...prevState,
            offset: 0,
          }));
        }
      }
    }
  }, [agentOfficeDetails]);

  function addOfficesForm() {
    return (
      <div className="broker-create-account agent_admin_form_modal">
        <Box className="create_acc_form_wrapper mt-0">
          <Box className="box-container agents_form agent_admin_form_modal">
            <Formik
              enableReinitialize
              validateOnChange
              initialValues={{
                office_name: selectedProperty?.office_name || "",
                address: selectedProperty?.address || "",
                city: selectedProperty?.city || "",
                state: selectedProperty?.state || "",
                zip: selectedProperty?.zip || "",
                phone: selectedProperty?.phone || "",
                website: selectedProperty?.website || "",
              }}
              validationSchema={agentOfficesValidationSchema}
              onSubmit={(values) => {
                if (!isValidPhoneNumber(values?.phone)) {
                  return;
                }
                onSubmit(values);
              }}
            >
              {({ values, setFieldValue, touched, errors, initialValues }) => {
                return (
                  <Form>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Office name
                        </InputLabel>
                        <CustomTextField
                          name="office_name"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Address
                        </InputLabel>
                        <CustomTextField
                          name="address"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          City
                        </InputLabel>
                        <CustomTextField
                          name="city"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          State
                        </InputLabel>
                        <FormControl
                          fullWidth
                          className="sign-textField text-center"
                          error={touched.state && Boolean(errors.state)}
                        >
                          <Select
                            className="text-start"
                            labelId="demo-simple-select-placeholder"
                            id="demo-simple-select"
                            value={values?.state}
                            name="state"
                            variant="outlined"
                            onChange={(event) => {
                              setFieldValue("state", event.target.value);
                            }}
                            MenuProps={MenuProps}
                            displayEmpty
                          >
                            <MenuItem disabled value="">
                              <span style={{ color: "#00000075" }}>State</span>
                            </MenuItem>
                            {USA_STATES?.map((state) => (
                              <MenuItem value={state?.code}>
                                {state?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.state && Boolean(errors.state) && (
                            <FormHelperText sx={{ color: "#d32f2f" }}>
                              {touched.state && errors.state}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Zip
                        </InputLabel>
                        <CustomTextField
                          name="zip"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Mobile number
                        </InputLabel>
                        <CustomPhoneNumberInput
                          name="phone"
                          placeholder={null}
                          values={values}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          otherProps={{
                            onChange: (event) => {
                              setFieldValue("phone", event);
                              if (!event) {
                                setFieldValue("phone", "");
                              }
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} px={1} pb={1}>
                        <InputLabel shrink htmlFor="bootstrap-input">
                          Website
                        </InputLabel>
                        <CustomTextField
                          name="website"
                          validation={{
                            values,
                            setFieldValue,
                            touched,
                            errors,
                          }}
                        />
                      </Grid>
                    </Grid>
                    {getObjLength(createAgentOfficeDetails) &&
                      !createAgentOfficeDetails?.success && (
                        <p className="fw-500 terms_error w-100 text-danger text-center">
                          {createAgentOfficeDetails?.data?.message}
                        </p>
                      )}
                    <Grid
                      item
                      xs={12}
                      md={12}
                      px={1}
                      className="d-flex justify-content-between align-items-center mt-3"
                    >
                      <Button
                        onClick={closeAddOfficeModal}
                        className="popup_Btn other_popup_btn"
                        variant="contained"
                      >
                        Discard
                      </Button>

                      <Button
                        type="submit"
                        variant="contained"
                        className={`popup_Btn success_popup_btn  ${
                          JSON.stringify(initialValues) ===
                            JSON.stringify(values) && "cursor-disable"
                        }`}
                        disabled={
                          JSON.stringify(initialValues) ===
                          JSON.stringify(values)
                        }
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <CommonAgentsAdminTable
        columns={columns}
        rowData={agentOfficeDetails?.success ? agentOfficeDetails?.data : []}
        btn_texts="Add Office"
        addBtnClick={openAddOfficeModal}
        pageCount={pageCount}
        setPageCount={setPageCount}
        setPayload={setPayload}
        setEditRecord={handleEditOfficeModal}
        setSelectedProperty={setSelectedProperty}
        selectedPropertyID={selectedProperty?.id}
        deleteAPIURL={"broker/delete-office"}
        customeDeleteMessage="Are you sure you want to delete this Office? This action can not be undone."
        listAPICall={agentOfficesListAPICall}
      />
      {/* Add Office form modal */}
      {agentOfficeModalOpen && (
        <DialogBox
          width={
            getObjLength(createAgentOfficeDetails) &&
            createAgentOfficeDetails?.success
              ? "sm"
              : "md"
          }
          contentClassName={"bg-white p-0"}
          openPopup={agentOfficeModalOpen}
          setOpenPopup={setAgentOfficeModalOpen}
          onClose={
            getObjLength(createAgentOfficeDetails) &&
            createAgentOfficeDetails?.success
              ? false
              : closeAddOfficeModal
          }
          content={
            !getObjLength(createAgentOfficeDetails) ||
            !createAgentOfficeDetails?.success ? (
              <>{addOfficesForm()}</>
            ) : (
              <PlainAlertMessage
                type="Success"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  agentOfficesListAPICall();
                  closeAddOfficeModal();
                }}
                message={createAgentOfficeDetails?.message}
                footer={false}
              />
            )
          }
        />
      )}
      <Loader open={agentAdminLoader} />
    </div>
  );
};

export default AgentOffices;
