import React from "react";
import CommonAgentsAdminTable from "./CommonAgentsAdminTable";

const columns = [
  {
    key: 1,
    label: "",
    id: "sr",
    minWidth: 10,
  },
  {
    key: 2,
    label: "Agent name",
    id: "agent_name",
  },
  {
    key: 3,
    label: "Office name",
    id: "office_name",
  },

  {
    key: 4,
    label: "Sales representative",
    id: "sales_representative",
  },
  {
    key: 5,
    label: "Status",
    id: "status",
  },
];

const SalesManagerREAgents = ({ base_route }) => {
  return (
    <div>
      <CommonAgentsAdminTable
        columns={columns}
        rowData={[]}
        btn_texts="Add Agent"
      />
    </div>
  );
};

export default SalesManagerREAgents;
