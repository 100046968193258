import React, { useState } from "react";

import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import { Box, Menu, MenuItem, TableHead } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import { PER_PAGE } from "../../helpers/constants";
import DeleteModal from "../../common/DeleteModal";
import SettingsIconSVG from "../../common/SettingsIconSVG";
import { getFormatedPhoneNumber } from "../../helpers/utils";
import { StyledTableCell, StyledTableRow } from "../../investor/list/ListTable";
import CustomPagination from "../../common/customDesignedComponents/CustomPagination";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

const CommonAgentsAdminTable = ({
  rowData = [],
  columns = [],
  btn_texts = "",
  addBtnClick = () => {},
  pageCount = 0,
  setPayload = () => {},
  setEditRecord = () => {},
  setSelectedProperty = () => {},
  selectedPropertyID = null,
  deleteAPIURL = "",
  listAPICall = () => {},
  customeDeleteMessage = null,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [deletePropetyModal, setDeletePropetyModal] = useState(false);

  const { deletePropertyData, deletePropertyLoader } = useSelector(
    (state) => state?.propertyUsers
  );

  const handleDeleteProeprty = () => {
    if (selectedPropertyID) {
      dispatch(
        propertyUsersAction.getDeletePropertyData({
          url: `${deleteAPIURL}/${selectedPropertyID}`,
          data: {},
          brokertoken: true,
          type: "delete",
        })
      );
    }
  };
  const handleDeleteProeprtyOption = (type = false) => {
    setDeletePropetyModal(type);
    dispatch(propertyUsersAction.clearDeletePropertyData({}));
  };

  const handleMenuClick = (event, data) => {
    setSelectedProperty(data);
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({
      ...prevState,
      offset: (p - 1) * PER_PAGE,
    }));
  };
  return (
    <>
      <TableContainer
        component={Paper}
        className=""
        sx={{
          //  maxHeight: 885,
          boxShadow: "0px 1px 0px 0px #344B5B0F",
        }}
      >
        <Table sx={{ minWidth: 1100 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((col, index) => (
                <StyledTableCell
                  head__bg={"#F3F4F5"}
                  key={index}
                  minWidth={col?.minWidth}
                  maxWidth={col?.maxWidth}
                  width={col?.width}
                >
                  {col?.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {rowData?.map((row, index) => {
              return (
                <>
                  <StyledTableRow key={index}>
                    {columns?.map((col, colIndex) => (
                      <StyledTableCell
                        key={colIndex}
                        minWidth={col?.minWidth}
                        maxWidth={col?.maxWidth}
                        width={col?.width}
                      >
                        {col.id === "action" ? (
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              handleMenuClick(event, row);
                            }}
                            size="small"
                            className="settings-button"
                            aria-haspopup="true"
                          >
                            <SettingsIconSVG color={"#15BE53"} />
                          </IconButton>
                        ) : col.id === "sr" ? (
                          index + 1
                        ) : col.id === "phone" ? (
                          getFormatedPhoneNumber(row[col.id])
                        ) : col.id === "agent_status" ? (
                          <span
                            className={`${
                              row[col.id] === "active" && "active_status"
                            } ${row[col.id] === "pending" && "pending_status"}`}
                          >
                            {row[col.id]}
                          </span>
                        ) : (
                          row[col.id] ?? "-"
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </>
              );
            })}
          </TableBody>
        </Table>

        {!rowData?.length > 0 && (
          <Typography className="p-5 text-center" variant="h6">
            {"No Data Found."}
          </Typography>
        )}
      </TableContainer>
      <Box className="mt-5" id="pagination-container">
        {pageCount > 1 && (
          <CustomPagination
            count={pageCount}
            page={page}
            handlePageChange={handlePageChange}
          />
        )}
      </Box>

      {/* Menu Options */}
      <Menu
        // key={index}
        anchorEl={anchorEl}
        id="settings-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <MenuItem
          key="premium_subscription"
          onClick={setEditRecord}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <EditIcon />
            <Typography className="setting-menu-text">Edit</Typography>
          </Box>
        </MenuItem>
        <MenuItem
          key="premium_subscription"
          onClick={() => handleDeleteProeprtyOption(true)}
          className="setting-menu-item"
        >
          <Box className="setting-menu-box">
            <DeleteOutlineIcon />
            <Typography className="setting-menu-text">Delete</Typography>
          </Box>
        </MenuItem>
      </Menu>

      {btn_texts && (
        <div className="agents_buttons justify-content-center mt-5">
          <Button onClick={addBtnClick} className="save-agents-btn">
            {btn_texts}
          </Button>
        </div>
      )}

      {/* Delete Record */}
      {deletePropetyModal && (
        <DeleteModal
          open={deletePropetyModal}
          title="Delete Property"
          item="Property"
          deleteLoader={deletePropertyLoader}
          handleClose={() => handleDeleteProeprtyOption(false)}
          handleDeleteBtn={handleDeleteProeprty}
          fetchedData={deletePropertyData}
          isDeleteSuccess={deletePropertyData?.success}
          successMessage={deletePropertyData?.message}
          errorMessage={deletePropertyData?.data?.message}
          callListingAPI={listAPICall}
          customeMessage={customeDeleteMessage}
        />
      )}
    </>
  );
};

export default CommonAgentsAdminTable;
