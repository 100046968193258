import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTabPanel({ tabContent = [], base_route = "" }) {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="tab_component" sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          className="tab_content"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabContent?.map((tabs, index) => (
            <Tab
              className="tab_header"
              value={tabs?.value}
              label={tabs?.label}
              {...a11yProps(index + 1)}
            />
          ))}
        </Tabs>
      </Box>
      {tabContent?.map((tabs, index) => (
        <>
          <TabPanel value={value} index={index + 1} base_route={base_route}>
            {tabs?.tabInfoText && (
              <p className="tab_texts">{tabs?.tabInfoText}</p>
            )}
            {value === index + 1 ? tabs.component(base_route) : null}
          </TabPanel>
        </>
      ))}
    </Box>
  );
}
