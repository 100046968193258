import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import DialogBox from "../../common/DialogBox";
import { getObjLength } from "../../helpers/utils";
import { BrokerSignupPage } from "../BrokerSignupPage";
import CommonAgentsAdminTable from "./CommonAgentsAdminTable";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { brokerAgentsAction } from "../../../redux/slices/brokers";
import { agentsAdminAction } from "../../../redux/slices/brokers/agentAdminSlice";
import { PER_PAGE } from "../../helpers/constants";
const columns = [
  {
    key: 1,
    label: "",
    id: "action",
    minWidth: 10,
  },
  {
    key: 2,
    label: "Agent name",
    id: "full_name",
  },
  {
    key: 3,
    label: "Office name",
    id: "office_name",
  },
  {
    key: 4,
    label: "Email",
    id: "email",
  },

  {
    key: 5,
    label: "Total monitoring",
    id: "agent_properties_count",
  },
  {
    key: 6,
    label: "Status",
    id: "agent_status",
  },
];
const AgentsList = ({ base_route = "broker" }) => {
  const dispatch = useDispatch();
  console.log("base_route==>", base_route);

  const [addAgentsModalOpen, setAddAgentsModalOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [payload, setPayload] = useState({
    limit: 10,
    offset: 0,
  });
  const [isEditAgentData, setIsEditAgentData] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});

  const manageSelectedProeprty = (data) => {
    setSelectedProperty(data);
    dispatch(brokerAgentsAction.fetchAgentProfileData({ data: data }));
  };

  const { agentAdminLoader, agentsDetails } = useSelector(
    (state) => state?.agentsAdmin
  );
  const { brokerDetails, agentCreateAccDetails } = useSelector(
    (state) => state?.brokerAgents
  );

  const addAgentProfile = (values, selectedAgentProfile = undefined) => {
    let formData = { ...values };
    delete formData?.password;
    delete formData?.new_password;
    delete formData?.old_password;

    if (
      isEditAgentData &&
      formData?.profile_img === selectedProperty?.agent_profile_img
    ) {
      delete formData.profile_img;
    }
    if (isEditAgentData && formData?.logo === selectedProperty?.logo) {
      delete formData.logo;
    }
    if (base_route !== "title") {
      delete formData.representative;
    }

    console.log("formData==>", formData);
    let url = `broker/create-agent`;
    if (isEditAgentData) {
      url = `broker/update-agent/${selectedProperty?.id}`;
    }
    dispatch(
      brokerAgentsAction.createAgentAccount({
        url,
        data: {
          ...formData,
          source: base_route,
          agent_json: selectedAgentProfile,
          broker: brokerDetails?.data?.broker?.id,
        },
        brokertoken: true,
      })
    );
  };
  const openAddAgentsModal = () => {
    setSelectedProperty();
    setIsEditAgentData(false);
    dispatch(brokerAgentsAction.fetchAgentProfileData({}));
    setAddAgentsModalOpen(true);
  };
  const closeAddAgentsModal = () => {
    setAddAgentsModalOpen(false);
    setSelectedProperty();
    setIsEditAgentData(false);
    dispatch(brokerAgentsAction.fetchAgentProfileData({}));
    dispatch(brokerAgentsAction.fetchAgentCreateAccData({}));
    dispatch(brokerAgentsAction.clearAgentProfilePayload({}));
    dispatch(brokerAgentsAction.fetchAgentProfileListData({}));
    window.scrollTo(0, 0);
  };

  const handleEditAgentModal = () => {
    setAddAgentsModalOpen(true);
    setIsEditAgentData(true);
    dispatch(agentsAdminAction.fetchCreateAgentOfficeData({}));
  };

  const agentsListAPICall = useCallback(() => {
    let data = {
      limit: payload?.limit,
      offset: payload?.offset,
      // domain: broker,
    };
    dispatch(
      agentsAdminAction.getAgentsDetails({
        url: `broker/get-agents`,
        data,
        brokertoken: true,
      })
    );
  }, [dispatch, payload?.limit, payload?.offset]);

  useEffect(() => {
    agentsListAPICall();
  }, [agentsListAPICall]);

  useEffect(() => {
    if (getObjLength(agentsDetails)) {
      if (agentsDetails?.success) {
        if (pageCount === 0)
          setPageCount(Math.ceil(agentsDetails?.count / PER_PAGE));

        if (agentsDetails?.count === 10) {
          setPayload((prevState) => ({
            ...prevState,
            offset: 0,
          }));
        }
      }
    }
  }, [agentsDetails]);

  return (
    <div>
      <CommonAgentsAdminTable
        columns={columns}
        rowData={agentsDetails?.success ? agentsDetails?.data : []}
        btn_texts="Add Agent"
        addBtnClick={openAddAgentsModal}
        pageCount={pageCount}
        setPageCount={setPageCount}
        setPayload={setPayload}
        setEditRecord={handleEditAgentModal}
        setSelectedProperty={manageSelectedProeprty}
        selectedPropertyID={selectedProperty?.id}
        deleteAPIURL={"broker/delete-agent"}
        customeDeleteMessage="Are you sure you want to delete this Agent? This action can not be undone."
        listAPICall={agentsListAPICall}
      />

      {addAgentsModalOpen && (
        <DialogBox
          width={
            getObjLength(agentCreateAccDetails) &&
            agentCreateAccDetails?.success
              ? "sm"
              : "lg"
          }
          contentClassName={"bg-white p-0"}
          openPopup={addAgentsModalOpen}
          setOpenPopup={setAddAgentsModalOpen}
          onClose={
            getObjLength(agentCreateAccDetails) &&
            agentCreateAccDetails?.success
              ? false
              : closeAddAgentsModal
          }
          content={
            !getObjLength(agentCreateAccDetails) ||
            !agentCreateAccDetails?.success ? (
              <BrokerSignupPage
                hide_top_footer_section={true}
                add_agent_profile={true}
                update_agent_profile={false}
                updateAgentProfile={addAgentProfile}
                closeAgentProfileModal={closeAddAgentsModal}
                base_route={base_route}
              />
            ) : (
              <PlainAlertMessage
                type="Success"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  agentsListAPICall();
                  closeAddAgentsModal();
                }}
                message={agentCreateAccDetails?.message}
                footer={false}
              />
            )
          }
        />
      )}
    </div>
  );
};

export default AgentsList;
