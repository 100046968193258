import React, { useCallback, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import DialogBox from "../../common/DialogBox";
import { getObjLength } from "../../helpers/utils";
import { PER_PAGE } from "../../helpers/constants";
import AdminAccountSettings from "./AdminAccountSettings";
import CommonAgentsAdminTable from "./CommonAgentsAdminTable";
import PlainAlertMessage from "../../common/PlainAlertMessage";
import { agentsAdminAction } from "../../../redux/slices/brokers/agentAdminSlice";
import { Loader } from "../../common/loader";

const columns = [
  {
    key: 1,
    label: "",
    id: "action",
    minWidth: 10,
  },
  {
    key: 2,
    label: "Admin name",
    id: "full_name",
  },
  {
    key: 3,
    label: "Office name",
    id: "office_name",
  },

  {
    key: 4,
    label: "Email",
    id: "email",
  },
  {
    key: 5,
    label: "Phone number",
    id: "phone",
  },
  {
    key: 6,
    label: "Website",
    id: "website",
  },
];

const AdminOverview = ({ base_route = "broker" }) => {
  const dispatch = useDispatch();

  const [addAgentAdminsOverviewModalOpen, setAddAgentAdminsOverviewModalOpen] =
    useState(false);
  const [payload, setPayload] = useState({
    limit: 10,
    offset: 0,
  });
  const [pageCount, setPageCount] = useState(0);
  const [isEditAgentOffice, setIsEditAgentOffice] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({});

  const { createAdminLoader, agentAdminDetails, createAgentAdminDetails } =
    useSelector((state) => state?.agentsAdmin);

  const openAgentAdminsOverviewModal = () => {
    setSelectedProperty();
    setAddAgentAdminsOverviewModalOpen(true);
  };
  const closeAgentAdminsOverviewModal = () => {
    setSelectedProperty();
    setIsEditAgentOffice(false);
    setAddAgentAdminsOverviewModalOpen(false);
    dispatch(agentsAdminAction.fetchCreateAgentAdminData({}));
  };

  const handleEditAdminModal = () => {
    setAddAgentAdminsOverviewModalOpen(true);
    setIsEditAgentOffice(true);
    dispatch(agentsAdminAction.fetchCreateAgentOfficeData({}));
  };

  const onFormSubmit = (values) => {
    const data = { ...values, skip_verification: 1 };
    delete data?.password;
    delete data?.old_password;
    delete data?.logo;
    if (isEditAgentOffice) {
      if (selectedProperty?.profile_img === data?.profile_img) {
        delete data?.profile_img;
      }
    }
    if (data?.profile_img?.length === 0 && !isEditAgentOffice) {
      delete data?.profile_img;
    }
    console.log("Values...", data);
    let url = `broker/create-sales-manager`;
    if (isEditAgentOffice) {
      url = `broker/update-sales-manager/${selectedProperty?.id}`;
    }
    dispatch(
      agentsAdminAction.createAgentAdminDetails({
        url,
        data,
        brokertoken: true,
      })
    );
  };

  const agentAdminsOverviewListAPICall = useCallback(() => {
    let data = {
      limit: payload?.limit,
      offset: payload?.offset,
    };
    dispatch(
      agentsAdminAction.getAgentAdminDetails({
        url: `broker/get-sales-managers`,
        data,
        brokertoken: true,
      })
    );
  }, [dispatch, payload?.limit, payload?.offset]);

  useEffect(() => {
    agentAdminsOverviewListAPICall();
  }, [agentAdminsOverviewListAPICall]);

  useEffect(() => {
    if (getObjLength(agentAdminDetails)) {
      if (agentAdminDetails?.success) {
        setPageCount(Math.ceil(agentAdminDetails?.count / PER_PAGE));
        if (agentAdminDetails?.count === 10) {
          setPayload((prevState) => ({
            ...prevState,
            offset: 0,
          }));
        }
      }
    }
  }, [agentAdminDetails]);

  return (
    <div>
      <CommonAgentsAdminTable
        columns={columns}
        btn_texts="Add Admin"
        rowData={agentAdminDetails?.success ? agentAdminDetails?.data : []}
        addBtnClick={openAgentAdminsOverviewModal}
        pageCount={pageCount}
        setPageCount={setPageCount}
        setPayload={setPayload}
        setEditRecord={handleEditAdminModal}
        setSelectedProperty={setSelectedProperty}
        selectedPropertyID={selectedProperty?.id}
        deleteAPIURL={"broker/delete-sales-manager"}
        customeDeleteMessage="Are you sure you want to delete this Office? This action can not be undone."
        listAPICall={agentAdminsOverviewListAPICall}
      />
      {/* Add Office form modal */}
      {addAgentAdminsOverviewModalOpen && (
        <DialogBox
          width={
            getObjLength(createAgentAdminDetails) &&
            createAgentAdminDetails?.success
              ? "sm"
              : "lg"
          }
          contentClassName={"bg-white p-0"}
          openPopup={addAgentAdminsOverviewModalOpen}
          setOpenPopup={setAddAgentAdminsOverviewModalOpen}
          onClose={
            getObjLength(createAgentAdminDetails) &&
            createAgentAdminDetails?.success
              ? false
              : closeAgentAdminsOverviewModal
          }
          content={
            !getObjLength(createAgentAdminDetails) ||
            !createAgentAdminDetails?.success ? (
              <>
                <AdminAccountSettings
                  closeAgentAdminsModal={closeAgentAdminsOverviewModal}
                  onFormSubmit={onFormSubmit}
                  isEdit={isEditAgentOffice}
                  selectedProperty={selectedProperty}
                />
              </>
            ) : (
              <PlainAlertMessage
                type="Success"
                openPopup={true}
                onClose={true}
                onCloseFun={() => {
                  agentAdminsOverviewListAPICall();
                  closeAgentAdminsOverviewModal();
                }}
                message={createAgentAdminDetails?.message}
                footer={false}
              />
            )
          }
        />
      )}
      <Loader open={createAdminLoader} />
    </div>
  );
};

export default AdminOverview;
