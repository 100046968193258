import { useState } from "react";
import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import styled from "@emotion/styled";
import { Box, MenuItem, Select, Tab, Tabs } from "@mui/material";

import { userProfileAction } from "../../redux/slices/user/userProfileSlice";

const StyledSubTabs = styled(Tabs)`
  .MuiButtonBase-root.MuiTab-root {
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    color: #0395ff;
    border: none !important;
    border-bottom: 2px solid #d7dfe7 !important;
    text-transform: none;
    font-family: IBM Plex Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }

  .MuiButtonBase-root.MuiTab-root.Mui-selected {
    border-top: 1px solid #d7dfe7 !important;
    border-left: 1px solid #d7dfe7 !important;
    border-right: 1px solid #d7dfe7 !important;
    border-bottom: none !important; /* not working */
    line-height: 18px;
    color: #0d120b;
    background-color: transparent;
  }

  .MuiTabs-indicator {
    display: none;
  }
`;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={`${props?.mClass}`} my={5} mx={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabComponent = ({
  tabContent,
  margin = "0 10px",
  mClass = "",
  hideTab = false,
  minHeight = "65vh",
  paddingTop = "",
}) => {
  const [value, setValue] = useState(1);
  const dispatch = useDispatch();
  const { callDefaultAPIs } = useSelector((state) => state?.userProfile);
  const handleChange = (event, newValue) => {
    dispatch(userProfileAction.setDefaultSelectedTab(newValue));
    setValue(newValue);
  };
  useEffect(() => {
    setValue(callDefaultAPIs);
  }, [callDefaultAPIs]);

  return (
    <>
      <Box className={`view-edit-page ${mClass}`}>
        <Box
          className={`d-block ${
            tabContent?.length > 3 ? ` d-lg-none` : `d-sm-none`
          }`}
          margin={margin}
        >
          <Select
            value={value}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            displayEmpty
            className="mobile-select-tabs w-100"
            inputProps={{ "aria-label": "Without label" }}
          >
            {tabContent?.map((tabs, index) => (
              <MenuItem
                sx={{
                  color: value === index + 1 ? "#0D120B" : "#0395FF",
                  paddingLeft: value === index + 1 ? "30px" : "",
                }}
                value={tabs?.value}
              >
                {tabs?.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        {!hideTab && (
          <StyledSubTabs
            value={value}
            scrollButtons
            onChange={handleChange}
            id="__new-tabs-container"
            aria-label="basic tabs example"
            className={`d-none ${mClass} ${
              tabContent?.length > 3 ? ` d-lg-block` : `d-sm-block`
            }`}
            sx={{ pl: 0, m: 0, mt: 4 }}
          >
            {tabContent?.map((tabs, index) => (
              <Tab
                // sx={{ ml: 0.3, mr: 0.3 }}

                value={tabs?.value}
                label={tabs?.label}
                {...a11yProps(index + 1)}
              />
            ))}
          </StyledSubTabs>
        )}
        <Box className={paddingTop} sx={{ minHeight: minHeight }}>
          {tabContent?.map((tabs, index) => (
            <CustomTabPanel mClass={mClass} value={value} index={index + 1}>
              {value === index + 1 ? tabs.component : null}
            </CustomTabPanel>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default CustomTabComponent;
