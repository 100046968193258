import React from "react";
import CommonAgentsAdminTable from "./CommonAgentsAdminTable";
const columns = [
  {
    key: 1,
    label: "Product",
    id: "product",
    minWidth: 10,
  },
  {
    key: 2,
    label: "Reference",
    id: "reference",
  },
  {
    key: 3,
    label: "Date",
    id: "date",
  },

  {
    key: 4,
    label: "Amount",
    id: "amount",
  },
  {
    key: 5,
    label: "Status",
    id: "status",
  },
  {
    key: 6,
    label: "",
    id: "file",
  },
];
const BillingPaymentTab = () => {
  return (
    <div>
      <CommonAgentsAdminTable columns={columns} rowData={[]} />
    </div>
  );
};

export default BillingPaymentTab;
