import React, { Suspense, useEffect } from "react";

import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  LazyLoadComponent,
  LazyLoadImage,
} from "react-lazy-load-image-component";

import { Stack } from "@mui/system";
import { Box, Grid } from "@mui/material";

import NavBar from "../layout/navbar";
import box from "../../assets/home/box.svg";
import { ROLES } from "../helpers/constants";
import scan from "../../assets/home/scan.svg";
import mail from "../../assets/home/mail.svg";
import list from "../../assets/home/list.svg";
import nbc from "../../assets/leadpage/nbc.WebP";
import fox from "../../assets/leadpage/fox.WebP";
import cbs from "../../assets/leadpage/cbs.WebP";
import cnn from "../../assets/leadpage/cnn.WebP";
import { decryptPayload } from "../helpers/utils";
import forbes from "../../assets/leadpage/forbes.WebP";
import bgimg from "../../assets/dashboard/attorney.webp";
import msnbc_logo from "../../assets/leadpage/msnbc.png";
import FAQsAccordianDesign from "../common/AccordianDesign";
import broker_bg from "../../assets/dashboard/broker_bg.png";
import usa_today from "../../assets/leadpage/usa_today.WebP";
import check_img from "../../assets/icons/checkmark_blk.png";
import protected_house from "../../assets/SEO/Protected House.webp";
import bgimg_mobile from "../../assets/dashboard/attorney-mobile.webp";
import remax_logo_blk from "../../assets/dashboard/remax_logo_black.png";
import remax_logo_wht from "../../assets/dashboard/remax_logo_white.png";
import EpOrbitTransitionSection from "../common/EpOrbitTransitionSection";

import "./attorney-page.scss";

const AttorneyPage = ({ isAgent = false, base_route = "broker" }) => {
  const navigate = useNavigate();
  const { broker } = useParams();

  const { brokerDetails } = useSelector((state) => state?.brokerAgents);

  const loggedInUserRole = decryptPayload(localStorage?.getItem("agent_role"));

  const handleAgentAccountBtn = () => {
    if (localStorage.getItem("agentauthToken")) {
      if (
        loggedInUserRole === ROLES?.brokerAdmin ||
        loggedInUserRole === ROLES.titleAdmin
      ) {
        navigate(`/${base_route}/${broker}/agent-admin`);
      } else {
        navigate(`/${base_route}/${broker}/agent-proeprties`);
      }
    } else {
      navigate(`/${base_route}/${broker}/agent-login`);
    }
  };

  return (
    <div className="attorney-program-page">
      <section className="natioal_host_bg_img">
        <NavBar
          isInvestors={false}
          by_ep_logo_blk={remax_logo_blk}
          by_ep_logo_white={remax_logo_wht}
          custom_logo={brokerDetails?.data?.broker?.logo}
          custom_logo_white={brokerDetails?.data?.broker?.logo_2}
          custom_logo_link={`/${base_route}/${broker}`}
          isLandingPage={true}
          hide_my_acc_btn={false}
          hide_call_us_now={true}
          isNavLinkLeftAlign={true}
          hideServiceProviderFromNavbar={true}
          textWhiteServiceProvider={"text-white"}
          handleAgentAccountBtn={handleAgentAccountBtn}
          hide_nav_links={isAgent}
        />
        <div className="first-section">
          <Container fluid className="mx-150 position-relative">
            <Row className="align-items-center section-height" spacing={3}>
              <Col xs={12} sm={12} md={12} lg={6} xl={5}>
                <Row className="mt-5 justify-content-center justify-content-lg-start text-start">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={10}
                    xl={12}
                    className="content-spacing-header"
                  >
                    <h1 className="heading-first mb-1">
                      Protect Your Clients' Property & Peace of Mind
                    </h1>
                    <p className="para-text-bold mt-3 font-weight-bold">
                      {isAgent
                        ? `Exclusive Offer form ${
                            brokerDetails?.data?.broker?.full_name || ""
                          } ${
                            base_route === "broker"
                              ? "Real Estate Experts"
                              : "Title"
                          }`
                        : "Exclusive Offer for Title Professionals, Attorneys, and Real Estate Experts"}
                    </p>
                    {isAgent ? (
                      <p className="para-text mt-2">
                        {brokerDetails?.data?.broker?.full_name || ""}{" "}
                        {base_route === "broker"
                          ? `in partnership with EquityProtect — the nation’s only true fraud protection company — 
                            offers Real Estate Professionals a free fraud protection program to share with their clients,
                            strengthening relationships while preventing cybercrime and fraud`
                          : `Title, in collaboration with EquityProtect, the nation's leading
                              fraud protection company, is providing Real Estate
                              Professionals a fraud monitoring program to share with
                              their cliets. This initiative improves homeownership by
                              fostering a stronger relationships between homeowners,
                              real estate agents, and title closing companies by
                              procatively alerting homeowners to potential cybercrime and fraud.`}
                      </p>
                    ) : (
                      <p className="para-text mt-2">
                        EquityProtect, the nation’s only true fraud protection
                        company, offers our Industry Partners a{" "}
                        <b>free $200 fraud protection </b>program to share with
                        their clients, strengthening relationships while
                        preventing cybercrime and fraud.
                      </p>
                    )}

                    {isAgent && (
                      <Stack
                        display={"flex"}
                        justifyContent={{
                          sm: "center",
                          md: "left",
                          lg: "left",
                        }}
                        marginTop={"30px"}
                        alignItems={"center"}
                        direction={{ xs: "column", sm: "row" }}
                        spacing={2}
                      >
                        <Link
                          className="text-white text-decoration-none"
                          to={`/${base_route}/${broker}/agent-create-account`}
                        >
                          <button className="button-width-mobile text-uppercase signin_button_v2 button_clr text-line-fix">
                            Create your free account
                          </button>
                        </Link>
                      </Stack>
                    )}
                  </Col>
                </Row>

                <div
                  className={`custom-mt-120 d-flex justify-content-evenly align-content-center pb-3 d-none d-lg-flex`}
                >
                  <Box className="homepage_logos_v2 d-flex d-none d-md-flex">
                    <LazyLoadImage src={nbc} alt="nbc" className="px-2" />
                    <LazyLoadImage src={forbes} alt="forbes" className="px-2" />
                    <LazyLoadImage src={fox} alt="fox" className="px-2" />
                    <LazyLoadImage src={cbs} alt="cbs" className="px-2" />
                    <LazyLoadImage
                      src={msnbc_logo}
                      alt="msnbc_logo"
                      className="px-2"
                    />
                    <LazyLoadImage
                      src={usa_today}
                      alt="usa_today"
                      className="px-2"
                    />
                    <LazyLoadImage src={cnn} alt="cnn" className="px-2" />
                  </Box>
                </div>
              </Col>
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={6}
                xl={6}
                className="text-center"
              >
                <div className="ep__bg-logo">
                  {isAgent ? (
                    <img
                      loading="lazy"
                      fetchpriority="high"
                      src={brokerDetails?.data?.broker?.landing_page_bg}
                      alt={""}
                    />
                  ) : (
                    <img
                      loading="lazy"
                      fetchpriority="high"
                      src={bgimg}
                      alt={""}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <div className="ep__bg-logo-mobile">
            {isAgent ? (
              <img
                loading="lazy"
                fetchpriority="high"
                src={broker_bg}
                alt={""}
              />
            ) : (
              <img
                loading="lazy"
                fetchpriority="high"
                src={bgimg_mobile}
                alt={""}
              />
            )}
          </div>
          <div className="mt-5 d-flex justify-content-center pb-3 d-xs-flex d-lg-none outer-div">
            <Box className="homepage_logos_v2 d-flex">
              <LazyLoadImage src={nbc} alt="nbc" className="px-2" />
              <LazyLoadImage src={forbes} alt="forbes" className="px-2" />
              <LazyLoadImage src={fox} alt="fox" className="px-2" />
              <LazyLoadImage src={cbs} alt="cbs" className="px-2" />
              <LazyLoadImage
                src={msnbc_logo}
                alt="msnbc_logo"
                className="px-2"
              />
              <LazyLoadImage src={usa_today} alt="usa_today" className="px-2" />
              <LazyLoadImage src={cnn} alt="cnn" className="px-2" />
            </Box>
          </div>
        </div>
      </section>

      <section className="section-2">
        <Container fluid className="position-relative">
          <Grid
            container
            className="title-monitoring-section position-relative overflow-hidden"
          >
            <Grid container item xs={12} md={12} lg={6} className="grid1">
              <Grid item xs={12}>
                <div className="">
                  <p className="notify_header">About Title Monitoring</p>
                  <h4 className="monitoring_essential">
                    Why Title Monitoring is Essential
                  </h4>
                  <p className="title-card-text mt-2">
                    Home title fraud is a growing concern, with alarming
                    statistics proving the need for proactive protection. Title
                    insurance offers excellent protection before closing and, in
                    some cases, extends coverage against fraud if the property
                    owner qualifies and has purchased additional protection.
                    EquityProtect complements title insurance by filling in the
                    gaps, providing an added layer of security where coverage
                    may fall short.
                  </p>
                </div>
              </Grid>
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12} sm={6} md={6} className=" ">
                  <p className="title-monitoring-cards d-flex align-items-center p-3 mb-0">
                    1 in 3 title companies reported experiencing fraud in Q1 of
                    2024. Real estate fraud losses skyrocketed from $213M (2020)
                    to $396M (2022).
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="">
                  <p className="title-monitoring-cards align-items-center p-3 mb-0">
                    Criminals forge title documents, take out fraudulent loans,
                    and list homes for sale – without the owner’s knowledge.
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className="">
                  <p className="title-monitoring-cards align-items-center p-3 mb-0">
                    Most services only alert you after fraud has happened.
                    EquityProtect prevents it before it starts.
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className=" ">
                  <p className="title-monitoring-cards align-items-center p-3 mb-0">
                    Ensure your clients’ peace of mind and position yourself as
                    a trusted advisor by offering them this powerful fraud
                    protection – at no cost!
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <EpOrbitTransitionSection />
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className="section-3 mt-5 mb-5">
        <Container fluid className="position-relative">
          <Row className="justify-content-center">
            <Col>
              <div className="text-center">
                <p className="notify_header">About Title Monitoring</p>
                <h3 className="sub_header mb-2 mb-md-4">
                  What You and Your Clients Receive
                </h3>
              </div>
              <Row className="mt-5 mb-5 text-start">
                <Col xs={12} sm={12} md={6} lg={3} className="content-spacing">
                  <LazyLoadImage
                    src={scan}
                    className="mb-3"
                    alt="Property Theft Protection"
                    width="auto"
                    height="auto"
                  />
                  <p className="protection_title">Free Title Monitoring</p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    Your clients' property titles are monitored 24/7, with
                    alerts sent within 24 hours of any suspicious activity or
                    recorded notice.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className="content-spacing">
                  <LazyLoadImage
                    src={mail}
                    className="mb-3"
                    alt="Smart Policy"
                    width="auto"
                    height="auto"
                  />
                  <p className="protection_title">Quarterly Branded Emails</p>
                  <p className="protection_desc pe-0 pe-lg-5 mb-0">
                    Stay connected with your clients through informative
                    updates, customized with your branding.
                  </p>
                  <p className="protection_desc pe-0 pe-lg-5 text-primary">
                    <i>*Requires $19.99 Annual Subscription for branding</i>
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className="content-spacing">
                  <LazyLoadImage
                    src={list}
                    className="mb-3"
                    alt="Property Tech Tools"
                    width="auto"
                    height="auto"
                  />
                  <p className="protection_title">Annual Risk Assessment</p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    A comprehensive fraud risk review is provided annually,
                    ensuring clients stay informed about potential
                    vulnerabilities.
                  </p>
                </Col>
                <Col xs={12} sm={12} md={6} lg={3} className="content-spacing">
                  <LazyLoadImage
                    src={box}
                    className="mb-3"
                    alt="Property Tech Tools"
                    width="auto"
                    height="auto"
                  />
                  <p className="protection_title">Comprehensive Coverage</p>
                  <p className="protection_desc pe-0 pe-lg-5">
                    We cover everything from single-family homes and multi-unit
                    residential properties to commercial real estate and even
                    raw land.
                  </p>
                </Col>
              </Row>
              <div className=" text-center my-4">
                {!isAgent && (
                  <h5 className="font-weight-bold mb-4 bold-text">
                    <b>All of this – a $200 value – is completely FREE!</b>
                  </h5>
                )}
                {isAgent ? (
                  <button
                    className="signin_button_v2 protection_bg"
                    onClick={() => {
                      navigate(`/${base_route}/${broker}/agent-create-account`);
                    }}
                  >
                    create your free account
                  </button>
                ) : (
                  <button
                    className="signin_button_v2 protection_bg"
                    onClick={() => {
                      navigate("/property-risk-management");
                    }}
                  >
                    claim your free offer today!
                  </button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-4">
        <Container fluid className="content___speacing ">
          <Row className="align-items-center">
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="img-container img-end"
            >
              <img src={protected_house} alt={protected_house} />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className="competitors-content-space"
            >
              <div className="TTP-content">
                <p className="notify_header padding-header mb-0">
                  Additional Services Offered
                </p>
                <h3 className="sub_header mb-2 mb-md-4">
                  How EquityProtect Differs from Competitors
                </h3>
                <p className="title-card-text mt-2">
                  In addition to monitoring title changes, EquityProtect offers
                  a premium service that goes beyond alerts—it actively
                  safeguards property owners from financial crime before it
                  happens. Unlike most "property title locking" services that
                  simply notify you after fraud has occurred, leaving homeowners
                  to face costly and time-consuming legal disputes,
                  EquityProtect provides proactive protection to prevent the
                  crime altogether.
                </p>
                {/* <h6 className="title-card-text mt-2">
                  EquityProtect is Different:
                </h6> */}
                <div className="ps-2 mt-2">
                  <Stack direction={"row"} className="mt-3">
                    <img src={check_img} className="check_img" alt="check" />
                    <span className="text-description">
                      Blocks unauthorized title changes & fraudulent mortgages
                      in real-time
                    </span>
                  </Stack>
                  <Stack direction={"row"} className="mt-2">
                    <img src={check_img} className="check_img" alt="check" />
                    <span className="text-description">
                      Monitors & protects against home title theft, fraudulent
                      loans, and fake property sales
                    </span>
                  </Stack>
                  <Stack direction={"row"} className="mt-2">
                    <img src={check_img} className="check_img" alt="check" />
                    <span className="text-description">
                      24/7 U.S.-based expert support – No overseas call centers!
                    </span>
                  </Stack>
                  <Stack direction={"row"} className="mt-2">
                    <img src={check_img} className="check_img" alt="check" />
                    <span className="text-description">
                      Provides legal defense coverage and full reimbursement if
                      fraud attempts occur
                    </span>
                  </Stack>
                  <Stack direction={"row"} className="mt-2">
                    <img src={check_img} className="check_img" alt="check" />
                    <span className="text-description">
                      SmartPolicy covers all losses incurred while enrolled
                    </span>
                  </Stack>
                </div>
                <div className="text-center mt-4">
                  <button
                    className="signin_button_v2 protection_bg text-uppercase"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Learn More About Premier Protection
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="section-5 faq_wrapper pt-1">
        <Container fluid className="py-5">
          <Suspense fallback={<div>Loading...</div>}>
            <LazyLoadComponent threshold={200}>
              <FAQsAccordianDesign />
            </LazyLoadComponent>
          </Suspense>
        </Container>
      </section>
    </div>
  );
};

export default AttorneyPage;
