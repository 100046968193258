import React, { useEffect, useRef, useState } from "react";

import { Form, Formik } from "formik";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Divider,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

import BrokerTopSection from "./BrokerTopSection";
import { ROLES, secretKeyEncrypt } from "../helpers/constants";
import CustomTextField from "../common/CustomTextField";
import { loginValidationSchema } from "../login/loginScreen";
import { encryptData, encryptPayload, getObjLength } from "../helpers/utils";
import BrokersPublicPageFooter from "./BrokersPublicPageFooter";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import ResetPassword from "../create-reset-password/resetPassword";
import CreateNewPass from "../create-reset-password/createNewPass";

const AgentLoginScreen = ({ base_route = "broker" }) => {
  const loginForm = useRef();
  const dispatch = useDispatch();
  const { broker } = useParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [isCreatePassword, setIsCreatePassword] = useState(false);

  const { loggedInAgentDetails } = useSelector((state) => state?.brokerAgents);

  const handleClickShowPassword = (setState) => setState((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = (values) => {
    console.log("Form data", values);
    localStorage.removeItem("roles");
    dispatch(
      brokerAgentsAction.getLoginAgentData({
        url: `login`,
        data: {
          ...values,
          broker_domain: broker,
          check_last_step: 0,
          source: base_route,
        },
      })
    );

    localStorage.clear();
    localStorage.setItem("preference", true);
  };

  const handleBacktoLogin = () => {
    setIsResetPassword(false);
    setIsCreatePassword(false);
  };
  useEffect(() => {
    if (getObjLength(loggedInAgentDetails)) {
      if (loggedInAgentDetails?.success) {
        localStorage.setItem(
          "agentauthToken",
          loggedInAgentDetails?.data?.AccessToken
        );
        localStorage.setItem(
          "agent_id",
          loggedInAgentDetails?.data?.user?.user_id
        );
        localStorage.setItem(
          "agent_role",
          encryptPayload(loggedInAgentDetails?.data?.role)
        );

        //scroll to top
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        if (
          loggedInAgentDetails?.data?.role === ROLES?.brokerAdmin ||
          loggedInAgentDetails?.data?.role === ROLES.titleAdmin
        ) {
          navigate(`/${base_route}/${broker}/agent-admin`);
        } else {
          navigate(`/${base_route}/${broker}/agent-proeprties`);
        }
      }
    }
  }, [loggedInAgentDetails]);

  console.log("loggedInAgentDetails==>", loggedInAgentDetails);

  return (
    <>
      <div className="broker-create-account">
        <BrokerTopSection
          headerTexts={"Login to Your Account"}
          subHeaderTexts={
            "To access your account, please enter your provided credentials below"
          }
          hide_my_acc_btn={true}
          base_route={base_route}
        />
        <section className="create_acc_form_wrapper mb-4">
          <Container fluid className="bg-white login-container pt-3">
            {!isResetPassword ? (
              <Box className="accountBoxWrapper Form_Wrapper">
                {/* <Typography align="center" variant="h3">
                    Log in to your account
                  </Typography>
                  <Typography
                    align="center"
                    variant="body1"
                    sx={{ marginBottom: "1rem !important" }}
                  >
                    To access your account, please enter your provided
                    credentials below
                  </Typography> */}
                <Box>
                  <Formik
                    enableReinitialize
                    validateOnChange
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    validationSchema={loginValidationSchema}
                    innerRef={loginForm}
                    onSubmit={(values) => {
                      const payload = JSON.parse(JSON.stringify(values));
                      payload.password = values?.password?.trim();
                      const encryptedData = encryptData(
                        payload?.password,
                        secretKeyEncrypt.toString()
                      );
                      payload.password = encryptedData;
                      // payload.role = "user";
                      onSubmit(payload);
                    }}
                  >
                    {({
                      values,
                      setFieldValue,
                      touched,
                      errors,
                      isSubmitting,
                    }) => {
                      return (
                        <Form>
                          <Grid
                            container
                            spacing={2}
                            textAlign={"center"}
                            alignItems={"center"}
                          >
                            <Grid item xs={12} md={6}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Email Address
                              </InputLabel>
                              <CustomTextField
                                name="email"
                                validation={{
                                  values,
                                  setFieldValue,
                                  touched,
                                  errors,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <InputLabel shrink htmlFor="bootstrap-input">
                                Create a Password
                              </InputLabel>
                              <OutlinedInput
                                className="textfield"
                                type={showPassword ? "text" : "password"}
                                name="password"
                                sx={{ backgroundColor: "#f6f9fc" }}
                                inputProps={{
                                  autoComplete: "new-confirm_password",
                                }}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle confirm_password visibility"
                                      onClick={() =>
                                        handleClickShowPassword(setShowPassword)
                                      }
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                }
                                value={values?.password}
                                fullWidth
                                onChange={(event) => {
                                  setFieldValue(
                                    "password",
                                    event.target.value?.trim()
                                  );
                                }}
                                helperText={touched.password && errors.password}
                                error={
                                  touched.password && Boolean(errors.password)
                                }
                              />
                              {touched.password && Boolean(errors.password) && (
                                <FormHelperText
                                  sx={{
                                    color: "rgb(211, 47, 47) !important",
                                    fontSize: "12px !important",
                                    m: "0 !important",
                                    maxWidth: "100% !important",
                                  }}
                                >
                                  {touched.password && errors.password}
                                </FormHelperText>
                              )}
                            </Grid>
                          </Grid>
                          {getObjLength(loggedInAgentDetails) &&
                            !loggedInAgentDetails?.success && (
                              <p className="text-danger text-center w-100 my-3">
                                {loggedInAgentDetails?.data?.message}
                              </p>
                            )}
                          <div className="text-center stepBtn mt-4">
                            <Button
                              className="login_button"
                              type="submit"
                              variant="contained"
                            >
                              Log In Now
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="text-center resetPwd mt-3">
                    <Button
                      //to="/reset-password"
                      onClick={() => {
                        setIsResetPassword(true);
                      }}
                    >
                      <span>Reset your password</span>
                    </Button>
                  </div>
                  <Divider className="separator my-3" />

                  <Box px={2} sx={{ marginBottom: "10px" }}>
                    <div className="text-center newAccountLink">
                      <Typography variant="caption" className="me-2">
                        Don’t have an account?
                      </Typography>
                      <Link
                        to={`/${base_route}/${broker}/agent-create-account`}
                        onClick={() => {
                          dispatch(brokerAgentsAction.fetchLoggedInAgentData());
                          localStorage.removeItem("CAPTCHA_Validate");
                        }}
                      >
                        Create one here
                      </Link>
                    </div>
                  </Box>
                </Box>
              </Box>
            ) : !isCreatePassword ? (
              <Box className="reset_password">
                <ResetPassword
                  isBroker={true}
                  backtoLogin={handleBacktoLogin}
                  setIsCreatePassword={setIsCreatePassword}
                />
              </Box>
            ) : (
              <Box className="create_password">
                <CreateNewPass
                  isBroker={true}
                  backtoLogin={handleBacktoLogin}
                />
              </Box>
            )}
          </Container>
        </section>
      </div>
      <BrokersPublicPageFooter />
    </>
  );
};

export default AgentLoginScreen;
