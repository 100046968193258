import { call, put, takeLatest } from "redux-saga/effects";

import { getAPIPayload } from "../../../apis/getApi";
import { postAPIPayload } from "../../../apis/postApi";
import { agentsAdminAction } from "../../slices/brokers/agentAdminSlice";

export function* fetchAgentsAdmninDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(agentsAdminAction.fetchAgentAdminData(response));
}

export function* fetchAdmninProfileDataSaga({ payload }) {
  const response = yield call(getAPIPayload, payload);

  // set reponse data in store
  yield put(agentsAdminAction.fetchAdminProfileData(response));
}

export function* fetchAgentsDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(agentsAdminAction.fetchAgentsData(response));
}

export function* fetchAgentsOfficeDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(agentsAdminAction.fetchAgentOfficeData(response));
}

export function* fetchCreateAgentsAdminDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(agentsAdminAction.fetchCreateAgentAdminData(response));
}

export function* fetchCreateAgentsOfficeDataSaga({ payload }) {
  const response = yield call(postAPIPayload, payload);

  // set reponse data in store
  yield put(agentsAdminAction.fetchCreateAgentOfficeData(response));
}
export function* watchAgentsAdminSagaAsync() {
  yield takeLatest(
    agentsAdminAction.getAgentAdminDetails,
    fetchAgentsAdmninDataSaga
  );
  yield takeLatest(
    agentsAdminAction.getAdminProfileData,
    fetchAdmninProfileDataSaga
  );
  yield takeLatest(agentsAdminAction.getAgentsDetails, fetchAgentsDataSaga);
  yield takeLatest(
    agentsAdminAction.getAgentOfficeDetails,
    fetchAgentsOfficeDataSaga
  );
  yield takeLatest(
    agentsAdminAction.createAgentAdminDetails,
    fetchCreateAgentsAdminDataSaga
  );
  yield takeLatest(
    agentsAdminAction.createAgentOfficeDetails,
    fetchCreateAgentsOfficeDataSaga
  );
}
