import React from "react";
import CommonAgentsAdminTable from "./CommonAgentsAdminTable";
const columns = [
  {
    key: 1,
    label: "",
    id: "action",
    minWidth: 10,
  },
  {
    key: 2,
    label: "Admin name",
    id: "admin_name",
  },
  {
    key: 3,
    label: "Office name",
    id: "office_name",
  },

  {
    key: 4,
    label: "Agents / Properties",
    id: "agents_properties",
  },
  {
    key: 5,
    label: "Phone number",
    id: "phone_number",
  },
  {
    key: 6,
    label: "Website",
    id: "website",
  },
];
const SalesRepresentativesTab = ({ base_route }) => {
  return (
    <div>
      <CommonAgentsAdminTable
        columns={columns}
        rowData={[]}
        btn_texts="Add sales representative"
      />
    </div>
  );
};

export default SalesRepresentativesTab;
