import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";

import { Loader } from "../common/loader";
import { getObjLength } from "../helpers/utils";
import AgentsSignupForm from "./AgentsSignupForm";
import BrokerTopSection from "./BrokerTopSection";
import AgentsProfileList from "./AgentsProfileList";
import BrokersPublicPageFooter from "./BrokersPublicPageFooter";
import { brokerAgentsAction } from "../../redux/slices/brokers";
import { lennarSignUpAction } from "../../redux/slices/lennar/signUp";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import OTPVerificationScreen from "../lennar/create-account/OTPVerificationScreen";

import "./BrokerSignupPage.scss";

export const BrokerSignupPage = ({
  hide_top_footer_section = false,
  update_agent_profile = false,
  add_agent_profile = false,
  updateAgentProfile = () => {},
  closeAgentProfileModal = () => {},
  base_route = "broker",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { broker } = useParams();

  const [screen, setScreen] = useState("create-account");
  const [formValues, setFormValues] = useState(null);
  const {
    agentCreateAccDetails,
    brokerAgentLoader,
    brokerDetails,
    loggedInAgentDetails,
    agentProfileListData,
    agentProfileLoader,
  } = useSelector((state) => state?.brokerAgents);

  const captchaRef = useRef();

  const backToCreateAccount = () => {
    setScreen("create-account");
    dispatch(brokerAgentsAction.fetchAgentCreateAccData({}));
    dispatch(brokerAgentsAction.fetchAgentProfileListData({}));
    dispatch(brokerAgentsAction.clearAgentProfilePayload());
    dispatch(lennarSignUpAction.resetLennarSignUpState({}));
  };

  const selectedAgentsProfile = (profileData) => {
    onAgentFormSubmit(formValues, profileData);
  };

  const goToAgentsProperties = () => {
    dispatch(
      brokerAgentsAction.getLoginAgentData({
        url: `login`,
        data: {
          email: formValues?.email,
          password: formValues?.password,
          broker_domain: broker,
          auto_login: 1,
          source: base_route,
        },
      })
    );
  };

  const onAgentFormSubmit = (values, selectedAgentProfile) => {
    delete values?.passwordcopy;
    if (values?.profile_img?.length === 0) {
      delete values?.profile_img;
    }
    if (values?.logo?.length === 0) {
      delete values?.logo;
    }
    localStorage.removeItem("phone_verified");
    if (add_agent_profile) {
      updateAgentProfile(values, selectedAgentProfile); // for add the Agents from Admin side
    } else {
      dispatch(
        brokerAgentsAction.createAgentAccount({
          url: `broker/send-otp`,
          data: {
            ...values,
            broker: brokerDetails?.data?.broker?.id,
            agent_json: selectedAgentProfile,
            source: base_route,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (getObjLength(loggedInAgentDetails) && !add_agent_profile) {
      dispatch(lennarSignUpAction.resetLennarSignUpState({}));
      dispatch(userSigupAction.setreCAPTCHA({}));
      captchaRef?.current?.reset();
      localStorage.removeItem("CAPTCHA_Validate");
      if (loggedInAgentDetails?.success) {
        localStorage.setItem(
          "agentauthToken",
          loggedInAgentDetails?.data?.AccessToken
        );
        if (!update_agent_profile && !add_agent_profile) {
          dispatch(brokerAgentsAction.resetBrokerAgentsState({}));
          localStorage.setItem(
            "agent_id",
            loggedInAgentDetails?.data?.user?.id
          );
        }
        navigate(`/${base_route}/${broker}/agent-proeprties`);
      } else {
        navigate(`/${base_route}/${broker}/agent-login`);
      }
    }
  }, [loggedInAgentDetails]);

  useEffect(() => {
    if (getObjLength(agentCreateAccDetails)) {
      dispatch(lennarSignUpAction.resetLennarSignUpState({}));
      if (
        getObjLength(agentCreateAccDetails) &&
        agentCreateAccDetails?.success
      ) {
        setScreen("otp");
        dispatch(brokerAgentsAction.clearAgentProfilePayload());
      }
      localStorage.removeItem("CAPTCHA_Validate");
      dispatch(userSigupAction.setreCAPTCHA({}));
      captchaRef?.current?.reset();
    }
  }, [agentCreateAccDetails]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (screen === "create-account") {
      dispatch(lennarSignUpAction.resetLennarSignUpState({}));
    }
  }, [screen]);

  useEffect(() => {
    dispatch(userSigupAction.setreCAPTCHA({}));
  }, []);

  return (
    <>
      <>
        <div className="broker-create-account">
          {!hide_top_footer_section && (
            <BrokerTopSection
              headerTexts={"Create Your Account"}
              subHeaderTexts={
                "Please provide the information required below to create your personal account."
              }
              base_route={base_route}
            />
          )}
          <section
            className={`create_acc_form_wrapper mb-4 ${
              (update_agent_profile || add_agent_profile) && "mt-0"
            }`}
          >
            <Container
              fluid
              className={`bg-white main-container ${
                update_agent_profile || add_agent_profile ? "mt-1" : "mt-5"
              }`}
            >
              {screen === "create-account" ? (
                <AgentsSignupForm
                  base_route={base_route}
                  setScreen={setScreen}
                  setFormValues={setFormValues}
                  formValues={formValues}
                  captchaRef={captchaRef}
                  onAgentFormSubmit={onAgentFormSubmit}
                  update_agent_profile={update_agent_profile}
                  add_agent_profile={add_agent_profile}
                  updateAgentProfile={updateAgentProfile}
                  closeAgentProfileModal={closeAgentProfileModal}
                />
              ) : screen === "otp" ? (
                <OTPVerificationScreen
                  //  path={path}
                  from={"broker"}
                  setScreen={setScreen}
                  user_id={agentCreateAccDetails?.data?.user_id}
                  formValues={formValues}
                  goToAgentsProperties={goToAgentsProperties}
                />
              ) : screen === "agents-profile-list" ? (
                <AgentsProfileList
                  agentList={agentProfileListData?.data}
                  selectedAgentsProfile={selectedAgentsProfile}
                  backToCreateAccount={backToCreateAccount}
                />
              ) : null}
            </Container>
          </section>
        </div>
        {!hide_top_footer_section && <BrokersPublicPageFooter />}
      </>
      <Loader open={brokerAgentLoader || agentProfileLoader} />
    </>
  );
};
